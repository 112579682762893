jQuery(document).ready(function () {
  jQuery('.hambergur-icon').on('click', function () {
    jQuery('body').addClass('modal-open');
  });

  jQuery('.hambergur-dropdown button.close').on('click', function () {
    jQuery('body').removeClass('modal-open');
  });

  // Removing padding from hamburger menu android mobile
  const isAndroid = /android/i.test(navigator.userAgent.toLowerCase());
  if (isAndroid) {
    jQuery('.top-nav-bar-common ul.main-nav-links').addClass('pb-0');
  } else {
    jQuery('.top-nav-bar-common ul.main-nav-links').removeClass('pb-0');
  }

  // On window resize hide hambergur
  jQuery(window).resize(function () {
    if (jQuery(window).width() >= 768) {
      if (jQuery('#megamenuModal').hasClass('show')) {
        $('#megamenuModal').modal('toggle');
      }
    }
  });
});
