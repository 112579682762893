import { minTabletWidth, minDesktopWidth } from './utils';

function assignImageUrl(value, type) {
  // Common function to add the image as background url based on site.
  const carouselImage = jQuery(value);
  const imageUrl = carouselImage.attr(type);
  carouselImage.css('background-image', `url('${imageUrl}')`);
}

function selectedCarousal() {
  $('.carousel-indicators a').each(function (i, ele) {
    $(ele).attr('aria-selected', $(ele).hasClass('active'));
  });
}

function carousalScreenResize() {
  // The window resize function to add the image based on size.
  const currentWidth = $(window).width();
  let screenType = 'desktop';
  if (currentWidth < minTabletWidth) {
    // Mobile
    screenType = 'mobile';
  } else if (currentWidth < minDesktopWidth) {
    // tablet
    screenType = 'tablet';
  }
  jQuery.each(jQuery('.carousel-item'), function addingUrl(index, value) {
    assignImageUrl(value, `data-image-${screenType}`);
  });
}

function carouselPause() {
  jQuery('#heroCarousel').on('slid.bs.carousel', function () {
    jQuery('#heroCarousel').carousel('pause');
  });
}

function carouselPlay() {
  jQuery('#heroCarousel').on('slid.bs.carousel', function () {
    jQuery('#heroCarousel').carousel('cycle');
  });
}

function carouselCurrentSlide() {
  $('#heroCarousel .carousel-item').attr('aria-live', 'off');
  $('#heroCarousel .carousel-item').removeAttr('role');
  jQuery('.carousel-control-pause').hide();
  jQuery('.carousel-control-play').show();
  jQuery('#heroCarousel').carousel('pause');
  setTimeout(function () {
    $('#heroCarousel .carousel-item.active').attr('aria-live', 'assertive');
    $('#heroCarousel .carousel-item.active').attr('role', 'status');
    // if ($('.carousel-item.active .carousel-caption-text a').attr('href') === '') {
    //   $('.carousel-item.active .carousel-caption-text a').attr('aria-hidden', 'true');
    // }
    // $('.carousel-item').removeAttr('tabindex');
    // $('.carousel-item.active').attr('tabindex', 0).focus();
  }, 1000);
}

jQuery(document).ready(function carousel($) {
  $('#heroCarousel .carousel-item').attr('aria-live', 'off');
  // handling the resize of the screen to change the carosal image/
  if (jQuery('#heroCarousel').length) {
    carousalScreenResize();
    $(window).resize(carousalScreenResize);
    jQuery('.bmo-carousel').swipe({
      swipe: function mobileSwipe(direction) {
        if (direction === 'left') $(this).carousel('next');
        if (direction === 'right') $(this).carousel('prev');
      },
      allowPageScroll: 'vertical',
    }, function () {
      carouselCurrentSlide();
    });
  }
  // Shifting focus to the carousel indicator on tabbing/enter from keyboard for accessibility
  jQuery('.carousel-indicators a').click(function () {
    jQuery(this).focus();
    carouselCurrentSlide();
  });

  jQuery('#heroCarousel .carousel-control-prev').on('click', function () {
    carouselCurrentSlide();
    $('#heroCarousel .carousel-control-prev').focus();
  });

  jQuery('#heroCarousel .carousel-control-next').on('click', function () {
    carouselCurrentSlide();
    $('#heroCarousel .carousel-control-next').focus();
  });
  const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|iPad/i.test(navigator.userAgent);

  //  Handling play and pause carousel section
  jQuery('.carousel-control-play').hide();
  jQuery('.carousel-control-play').on('click', function (event) {
    event.preventDefault();
    jQuery(this).hide();
    jQuery('.carousel-control-pause').show().focus();
    jQuery('#heroCarousel').carousel('cycle');
    if (isMobile) {
      carouselPlay();
    }
  });

  jQuery('.carousel-control-pause').on('click', function (event) {
    event.preventDefault();
    jQuery(this).hide();
    jQuery('.carousel-control-play').show().focus();
    jQuery('#heroCarousel').carousel('pause');
    if (isMobile) {
      carouselPause();
    }
  });

  jQuery('.carousel-caption-text h3').replaceWith(function () {
    const content = this.innerHTML;
    return `<h1>${content}</h1>`;
  });

  jQuery('.carousel-caption-text h4').replaceWith(function () {
    const content = this.innerHTML;
    return `<h1>${content}</h1>`;
  });

  jQuery('.carousel-caption-text h6').replaceWith(function () {
    const content = this.innerHTML;
    return `<p>${content}</p>`;
  });

  jQuery('.how-we-help-row h3').replaceWith(function () {
    const content = this.innerHTML;
    return `<h1>${content}</h1>`;
  });

  jQuery('#heroCarousel').on('slid.bs.carousel', function () {
    selectedCarousal();
  });
  selectedCarousal();
});
