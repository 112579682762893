import {
  minTabletWidth,
} from './utils';

function sortByName(desendingOrder) {
  const getRowWrapper = jQuery('#sort-rows');
  const allRowElements = getRowWrapper.find('.row').get();
  let sortedRows = _.sortBy(allRowElements, (rowEle) => {
    const economistName = _.lowerCase(jQuery(rowEle).find('.economist-name').data('economist-last-name'));
    return economistName;
  });
  if (desendingOrder) {
    sortedRows = _.reverse(sortedRows);
  }
  jQuery.each(sortedRows, function (index, item) {
    getRowWrapper.append(item);
  });
}

function sortByCoverage(desendingOrder) {
  const getCoverageRow = jQuery('#sort-rows .row');

  getCoverageRow.each(function () {
    const childEle = jQuery(this).find('.economist-coverage ul li').get();
    let sortedRows = _.sortBy(childEle, (liEle) => {
      const coverageList = _.lowerCase(jQuery(liEle).text());
      return coverageList;
    });
    if (desendingOrder) {
      sortedRows = _.reverse(sortedRows);
    }
    jQuery(this).find('.economist-coverage ul').append(sortedRows);
  });
}

function onSortClick() {
  jQuery('.sort-by-economist-name').on('click', function () {
    const iconElem = jQuery(this).find('em');
    const currentClass = iconElem.attr('class');
    if (currentClass === 'fas fa-sort-up') {
      iconElem.removeClass('fa-sort-up').addClass('fa-sort-down');
      sortByName(true);
    } else {
      iconElem.removeClass('fa-sort-down').addClass('fa-sort-up');
      sortByName(false);
    }
  });
}

function onSortClickCoverage() {
  jQuery('.sort-by-economist-coverage').on('click', function () {
    const iconElem = jQuery(this).find('em');
    const currentClass = iconElem.attr('class');
    if (currentClass === 'fas fa-sort-up') {
      iconElem.removeClass('fa-sort-up').addClass('fa-sort-down');
      sortByCoverage(true);
    } else {
      iconElem.removeClass('fa-sort-down').addClass('fa-sort-up');
      sortByCoverage(false);
    }
  });
}

/* Close economist preview window on resize */
function windowResizeFunction() {
  jQuery(window).resize(function () {
    if (jQuery(window).width() >= minTabletWidth) {
      if (jQuery('.economist-preview').hasClass('show')) {
        jQuery('.economist-preview').modal('hide');
      }
    }
  });
}

function ariaHiddenImages() {
  jQuery('#economists-list-container img.economist-photo').parent().attr({ 'aria-hidden': true, tabindex: -1 });
  jQuery('#our-economists-container img').parent().attr({ 'aria-hidden': true, tabindex: -1 });
  jQuery('.back-to-economists img').attr({ 'aria-hidden': true, tabindex: -1 });
  jQuery('.publication-result-card-block-wrap .author-image img').attr('aria-hidden', true);
}

function roleToBackToEconomists() {
  jQuery('.back-to-economists h2').attr('role', 'presentation');
  jQuery('.back-to-economists').attr('role', 'link');
}

jQuery(document).ready(function () {
  // Economists List function mainly used for sorting
  if (jQuery('#economists-list-container').length) {
    onSortClick();
    onSortClickCoverage();
    windowResizeFunction();
  }
  ariaHiddenImages();
  roleToBackToEconomists();
});
