import {
  BEDateFormat,
  defaultFilters,
  DEFAULT_NUMBER_OF_MONTHS,
  parseDateToURLDate,
  isDesktop,
  isMobile,
  isTab,
  selectCurrentActiveDay,
  calenderActiveRows,
} from './utils';

import {
  getEventsDataAPI,
  downloadICSFile,
} from './apis';

import CalenderWidget from './calendar_widget';

let MAX_EVENTS_TO_SHOW = 3;

function windowResize() {
  const filterCard = jQuery('#filterCard');
  if (isDesktop()) {
    filterCard.appendTo('.calender-strip-filter-column');
    jQuery('#calendarStripFilterModal').modal('hide');
  } else if (isMobile() || isTab()) {
    filterCard.appendTo('#calendar-strip-filter-modal');
  }
}

function onClickDownloadICSFile() {
  jQuery('.calender-strip').on('click', '.media.events-data .download-ics, .media.events-data .media-body', function onClickForICSDownload() {
    const clickedEle = jQuery(this);
    let id = '';
    if (clickedEle.hasClass('media-body')) {
      if (isDesktop()) {
        id = clickedEle.siblings('img.download-ics').attr('data-eventid');
      }
    } else if (clickedEle.hasClass('download-ics')) {
      id = clickedEle.attr('data-eventid');
    }
    if (id) {
      downloadICSFile(parseInt(id, 10));
    }
  });
}

export default class CalenderStripView {
  constructor() {
    this.calenderWidget = new CalenderWidget();
    this.currentDisplayedDate = moment().format(BEDateFormat);
    this.fromDate = defaultFilters.startDate;
    this.toDate = defaultFilters.endDate;
    this.eventsData = '';
  }

  setEventsData(htmlData) {
    const eventsData = htmlData.split('<div class="cbd-calendar-list">')[0];
    this.eventsData = eventsData;
  }

  setCurrentDisplayedDate(date) {
    this.currentDisplayedDate = date;
  }

  async getCalendarHTMLStructure() {
    let totalMonths = jQuery('#calenderStripContainer').attr('data-months');
    const fromDate = moment().format(BEDateFormat);
    if (totalMonths) {
      totalMonths = parseInt(totalMonths, 10);
    } else {
      totalMonths = DEFAULT_NUMBER_OF_MONTHS;
    }
    const toDate = moment().add(totalMonths, 'M').endOf('month').format(BEDateFormat);
    let htmlData = '';
    try {
      htmlData = await getEventsDataAPI(fromDate, toDate);
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
    this.fromDate = fromDate;
    this.toDate = toDate;
    return htmlData;
  }

  showCurrentDateEvents() {
    const commonComponents = jQuery('.common-structures');
    const event = jQuery(`.all-calender-strip-events .econ-calendar-list #econ-${parseDateToURLDate(this.currentDisplayedDate)}`);
    if (event.length) {
      // cloning the event and showing it here
      const clonedEvent = event.clone();
      const countryFilter = commonComponents.clone().find('.calender-strip-country-filter');
      // removing the calendar filter which doesnt have events
      const allMediaEle = clonedEvent.children('.media');
      const countryFilterList = [];
      _.map(allMediaEle, (ele) => {
        const element = jQuery(ele);
        if (element.hasClass('can')) {
          countryFilterList.push('can');
        }
        if (element.hasClass('usa')) {
          countryFilterList.push('usa');
        }
        if (element.hasClass('international')) {
          countryFilterList.push('international');
        }
        const allEventData = element.find('.media.events-data').get();
        const sortedByBool = _.orderBy(allEventData, (dataEle) => {
          const show = jQuery(dataEle).attr('data-showindesktop');
          if (show === 'False') {
            return 2;
          }
          return 1;
        });
        const eleList = _.slice(sortedByBool, 0, MAX_EVENTS_TO_SHOW);
        const sortedBytime = _.orderBy(eleList, (dataEle) => {
          const show = jQuery(dataEle).find('.media-body .date').attr('data-time');
          return show;
        });
        const parentEle = element.find('.parent-body');
        parentEle.empty();
        _.map(sortedBytime, (childEle) => {
          parentEle.append(childEle);
        });
      });
      _.map(countryFilterList, (country, indx) => {
        const ele = countryFilter.find(`.country-image-wrap.${country}`);
        const mediaEle = allMediaEle.filter(`.${country}`);
        mediaEle.find('img.flag').addClass('d-none d-md-block');
        if (indx === 0) {
          // making the first flag enabled
          ele.addClass('active');
        } else {
          mediaEle.addClass('d-none d-md-flex');
        }
        ele.removeClass('d-none');
      });
      countryFilter.on('click', '.country-image-wrap', (ele) => {
        countryFilter.find('.country-image-wrap').removeClass('active');
        allMediaEle.addClass('d-none d-md-flex');
        const imgEle = jQuery(ele.target);
        if (imgEle.is('img')) {
          imgEle.parent().addClass('active');
        } else {
          imgEle.addClass('active');
        }
        const country = imgEle.attr('data-country');
        allMediaEle.filter(`.${country}`).removeClass('d-none');
      });
      // appending the country filter for mobile
      allMediaEle.first().before(countryFilter);
      clonedEvent.attr('id', `current-${clonedEvent.attr('id')}`);
      jQuery('.calendar-strip-list-column').empty();
      clonedEvent.appendTo('.calendar-strip-list-column');
    } else {
      // Showing No events to show text
      const noEventsToShow = jQuery('.no-events-to-show').clone();
      const date = moment(this.currentDisplayedDate, BEDateFormat).format('ddd, MMM DD');
      noEventsToShow.prepend(`<h2>${date}</h2>`);
      noEventsToShow.appendTo('.calendar-strip-list-column');
    }
  }

  onDateSelect() {
    this.calenderWidget.calendarWidget.on('changeDate', (date) => {
      this.calenderWidget.currentDate = date.format();
      this.setCurrentDisplayedDate(date.format());
      this.showCurrentDateEvents();
      selectCurrentActiveDay();
    });
  }

  dateChangeOnCalendarCallBack(date) {
    this.calenderWidget.currentDate = date;
    this.setCurrentDisplayedDate(date);
    this.showCurrentDateEvents();
  }

  setFromToDateInWidget() {
    this.calenderWidget.setFromAndToDate(this.fromDate, this.toDate);
  }

  renderFilter() {
    this.setFromToDateInWidget();
    this.calenderWidget.setParentThis(this);
    this.calenderWidget.intitializeWidget();
    this.calenderWidget.disableEnableDates();
    this.onDateSelect();
  }

  addHtmlInDummyElement() {
    jQuery('.all-calender-strip-events').html(this.eventsData);
  }
}

jQuery(document).on('click focusin', function () {
  if (jQuery('.calendar-month-dropdown').is(':visible')) {
    jQuery('.datepicker-switch:visible').attr('aria-expanded', true);
  } else {
    jQuery('.datepicker-switch:visible').attr('aria-expanded', false);
  }
});

jQuery('#calendarStripFilterModal .close-button').on('focusout', function (e) {
  if ($('#calendarStripFilterModal').is(':visible')) {
    e.preventDefault();
    $('#calendarStripFilterModal .datepicker-days .datepicker-switch').focus();
  }
});
// jQuery('#filterModal').on('show.bs.modal', function() {
//   $('#calendarStripFilterModal .datepicker-days .datepicker-switch').focus();
// });

// Document ready function
jQuery(document).ready(async function () {
  if (jQuery('.calender-strip').length) {
    const calenderStrip = new CalenderStripView();
    const htmlData = await calenderStrip.getCalendarHTMLStructure();

    const maxEvents = jQuery('#calenderStripContainer').attr('data-maxeventtoshow');
    MAX_EVENTS_TO_SHOW = parseInt(maxEvents, 10);

    calenderStrip.setEventsData(htmlData);
    calenderStrip.addHtmlInDummyElement();

    calenderStrip.renderFilter();
    calenderStrip.showCurrentDateEvents();
    calenderStrip.calenderWidget.highlightEconDates();

    calenderStrip.calenderWidget.calendarWidget.datepicker('update', calenderStrip.currentDisplayedDate);
    jQuery('.datepicker-switch').attr('aria-expanded', false);
    jQuery('.datepicker .datepicker-title').attr({ 'aria-hidden': true, role: 'none' });
    jQuery('.datepicker tfoot tr th').attr({ 'aria-hidden': true, role: 'none' });
    jQuery('.datepicker-switch').parent().removeAttr('aria-hidden');
    jQuery('.datepicker-switch').parent().removeAttr('role');
    if (!$('.datepicker-days table tbody tr:last-child td').is(':visible')) {
      $('.datepicker-days table tbody tr:last-child').attr({ 'aria-hidden': true, role: 'none' });
    }
    jQuery('.calendar-date-wrap .events-data .media-body, .cbd-data-wrap .media-body.text').each(function (i, item) {
      jQuery(item).attr('tabindex', '0');
      jQuery(item).attr('aria-label', 'downloads ics file');
    });
    jQuery('.datepicker-switch').on('click blur', function (e) {
      setTimeout(function () {
        if (jQuery(e.target).hasClass('chevron-up')) {
          jQuery(e.target).attr('aria-expanded', true);
          jQuery('.calendar-month-dropdown li:first').attr('tabindex', 0).focus();
        } else {
          jQuery(e.target).attr('aria-expanded', false);
        }
      }, 300);
    });
    jQuery('.table-condensed thead th[role=button]').removeAttr('id');
    onClickDownloadICSFile();
    windowResize();
    jQuery(window).on('resize', windowResize);
  }
  selectCurrentActiveDay();
  calenderActiveRows();
  $('.datepicker.datepicker-inline .datepicker-prv-nxt .button-container a.prev').attr('aria-hidden', true);
  $('.datepicker.datepicker-inline .datepicker-prv-nxt .button-container a.next').attr('aria-hidden', true);
});
