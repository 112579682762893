import {
  isMobile,
  isTab,
  isDesktop,
} from '../utils';

function openToolTipDirection() {
  const fontToolTip = jQuery('.custom-tooltip.font-size');
  const searchToolTip = jQuery('.custom-tooltip.search');
  if (isMobile()) {
    fontToolTip.removeClass('right').addClass('left');
    searchToolTip.removeClass('right').addClass('left');
  } else {
    searchToolTip.removeClass('left').addClass('right');
    fontToolTip.removeClass('left').addClass('right');
  }
}

function onClickFunctionForActionButtons() {
  let hide = true;
  jQuery('body').on('click', function (e) {
    let notOnTooltip = true;
    const targetEle = jQuery(e.target);
    // prevent the close click on the tooltip.
    if (targetEle.hasClass('custom-tooltip') || targetEle.parents('.custom-tooltip').length) {
      notOnTooltip = false;
    }
    if (hide && notOnTooltip) {
      jQuery('.publication-action-button:not(.bookmark-icon)').removeClass('active');
    }
    hide = true;
  });
  jQuery('#publicationDetailsContainer').on('click', '.publication-action-button', function () {
    jQuery('.publication-action-button:not(.bookmark-icon)').removeClass('active');
    if (jQuery(this).hasClass('bookmark-icon')) {
      jQuery(this).toggleClass('active');
    } else {
      jQuery(this).addClass('active');
    }
    hide = false;
  });

  jQuery('#publicationDetailsContainer').on('click', '.bookmark-icon', function () {
    if (jQuery(this).hasClass('active')) {
      jQuery(this).attr('aria-label', 'unbookmarks the publication');
    } else {
      jQuery(this).attr('aria-label', 'bookmarks the publication');
    }
  });

  jQuery('#publicationDetailsContainer').on('keydown', 'font-change-button.size1', function (e) {
    if ((e.keyCode === 9) || (e.which === 9)) {
      if (e.shiftKey) {
        jQuery('.publication-action-button.font-size').focus();
      }
    }
  });
}

function onClickFunctionForFontChange() {
  jQuery('#publicationDetailsContainer').on('click', '.font-change-button', function () {
    const currEle = jQuery(this);
    jQuery('.font-change-button').removeClass('active');
    currEle.addClass('active');
    const handleSizeEle = jQuery('.handle-size-change');
    if (currEle.hasClass('size1')) {
      handleSizeEle.removeClass('size2 size3').addClass('size1');
    } else if (currEle.hasClass('size2')) {
      handleSizeEle.removeClass('size1 size3').addClass('size2');
    } else if (currEle.hasClass('size3')) {
      handleSizeEle.removeClass('size2 size1').addClass('size3');
    }
  });
}

function highlightText(text, searchText) {
  let srcStr = text;
  srcStr = srcStr.replace(/<mark>/g, '');
  srcStr = srcStr.replace(/<\/mark>/g, '');
  if (!_.isEmpty(searchText)) {
    const escapedTgtStr = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`${escapedTgtStr}(?=[^<>]*(<|$))`, 'gi');
    const count = (srcStr.match(regex) || []).length;
    if (count > 0) {
      srcStr = srcStr.replace(regex, '<mark>$&</mark>');
      if (isMobile()) {
        jQuery('#actionMobileModal').modal('hide');
      }
    } else {
      // seting no results to find true
      jQuery('.no-results-to-find').removeClass('d-none');
    }
    return srcStr;
  }
  return srcStr;
}

function focusTotheFirstValue() {
  const firstEle = jQuery('mark').first();
  if (firstEle.length) {
    jQuery('html, body').animate({
      scrollTop: ((firstEle.offset().top) - (jQuery(window).height() / 2)),
    }, 1000);
  }
}

function onSearchChange() {
  let oldSearchValue = '';
  jQuery('#publicationDetailsContainer').on('keyup', '#searchBarHighlight, #searchBarHighlightModal', function onChangeSearch(e) {
    const searchValue = e.target.value;
    if (((e.keyCode === 13) || (e.which === 13)) && oldSearchValue !== searchValue
      && (searchValue.length >= 2 || searchValue.length === 0)) {
      const toBeHighlightedEle = jQuery('.handle-search');
      toBeHighlightedEle.html(highlightText(toBeHighlightedEle.html(), searchValue));
      focusTotheFirstValue();
      e.preventDefault();
      oldSearchValue = searchValue;
    } else if ((e.keyCode !== 13) || (e.which !== 13)) {
      jQuery('.no-results-to-find').addClass('d-none');
    }
  });
}

function stickyActionBar() {
  const footerEle = jQuery('footer').get(0);
  const publicationActionEle = jQuery('.action-column .publication-action-container');
  if (isTab() || isDesktop()) {
    if ((jQuery(window).scrollTop() > (jQuery('.left-column').offset().top - 120) && isTab())
      || (jQuery(window).scrollTop() > 210 && isDesktop())) {
      publicationActionEle.removeClass('bottom-sticky-action').addClass('sticky-action').css('bottom', '');
    } else {
      publicationActionEle.removeClass('sticky-action');
    }
  }
  if (footerEle) {
    const footerRectObj = footerEle.getBoundingClientRect();
    if (((footerRectObj.top <= 665) && isDesktop())
      || ((footerRectObj.top <= 625) && isTab())) {
      if (!publicationActionEle.hasClass('bottom-sticky-action')) {
        const parentEle = jQuery('.action-column').get(0);
        if (parentEle) {
          const parentRectObj = parentEle.getBoundingClientRect();
          publicationActionEle.css('bottom', `${parentRectObj.bottom - footerRectObj.top + 120}px`);
        }
      }
      publicationActionEle.removeClass('sticky-action').addClass('bottom-sticky-action');
    } else {
      publicationActionEle.removeClass('bottom-sticky-action').css('bottom', '');
    }
  }
}

function windowResize() {
  stickyActionBar();
  openToolTipDirection();
  jQuery('#actionMobileModal').modal('hide');
}

function windowScroll() {
  stickyActionBar();
}

export default function actionButtonFunctions() {
  openToolTipDirection();
  onClickFunctionForActionButtons();
  onClickFunctionForFontChange();
  onSearchChange();
  stickyActionBar();
  jQuery(window).on('resize', windowResize);
  jQuery(window).on('scroll', windowScroll);
}
