jQuery(document).ready(function () {
  // Toggle function for mobile footer
  jQuery('.footer-sub-heading').click(function () {
    jQuery(this).next().toggleClass(' d-none d-sm-none');
    jQuery(this).find('.drop-down-icon').toggleClass('down');
    if (jQuery(this).find('.drop-down-icon').hasClass('down')) {
      jQuery(this).attr('aria-expanded', 'true');
    } else {
      jQuery(this).attr('aria-expanded', 'false');
    }
  });
});
