// Trending articles js file.

import {
  PublicationView,
  showNoPublications,
} from './publication_utils';

const BEDateFormat = 'MM/DD/YYYY';

// display the number of rows received by param.
function showMore(rowCount) {
  jQuery(`.trending-articles-main .publication-result-card:lt(${rowCount})`).show();
}

function showSeeMoreButton() {
  jQuery('.see-more-button .btn-outline-primary').show();
}

function hideSeeMoreButton() {
  jQuery('.see-more-button .btn-outline-primary').hide();
}

jQuery(document).ready(function () {
  if (jQuery('#trendingArticlesPlugin').length) {
    let totalRowLength = 0;

    // Set the number of publication to be shown on every see more click.
    const defaultRowCount = parseInt(jQuery('.trending-articles-table .full-width-table').attr('data-maxnoofrows'), 10) || 5;

    let rowCount = defaultRowCount || 5;
    showMore(rowCount); // Show 5 rows by defualt

    // Increment the count of row by 5 for every see more click.
    // If count increases the maximum, hide the button.
    jQuery('.see-more-button button').on('click', function () {
      rowCount = rowCount + defaultRowCount < totalRowLength
        ? rowCount += defaultRowCount : totalRowLength;

      showMore(rowCount);

      const focusCount = rowCount - 4;
      const focusEle = jQuery(`.publication-result-card:nth-child(${focusCount}) a:first`);

      $('html,body').animate({
        scrollTop: focusEle.offset().top - 100,
      }, 1500);
      focusEle.focus();

      if (rowCount === totalRowLength) {
        hideSeeMoreButton();
      }
    });

    // Initialise PublicationView Class
    const publicationView = new PublicationView();

    // Set the section
    publicationView.setSection('trending_articles');

    publicationView.setFilter(
      'limit_per_page',
      jQuery('#trendingArticlesPlugin').data('max-publications'),
    );

    publicationView.setFilter(
      'from_date',
      moment().subtract(3, 'months').format(BEDateFormat),
    );

    publicationView.setFilter(
      'section',
      'trending_articles',
    );

    // Render publication
    publicationView.renderPublicationsListing().then(function () {
      jQuery(`.trending-articles-main .publication-result-card:gt(${rowCount - 1})`).hide();
      totalRowLength = jQuery('.trending-articles-main .publication-result-card').length;

      showSeeMoreButton();

      if (totalRowLength <= 5) {
        hideSeeMoreButton();
      }
    }).catch(function () {
      showNoPublications();
    });
  }
});
