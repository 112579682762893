require('./custom/js/footer');
require('./custom/js/carousal');
require('./custom/js/sticky_nav_bar');
require('./custom/js/top_nav_bar');
require('./custom/js/bookmark');
require('./custom/js/calender_strip');
require('./custom/js/our_economists');
require('./custom/js/econofacts');
require('./custom/js/publication_updates');
require('./custom/js/recent_releases');
require('./custom/js/video_cast');
require('./custom/js/economist_profile');
require('./custom/js/publication_details');
require('./custom/js/trending_articles');
require('./custom/js/publication_list');
require('./custom/js/publication_utils');
require('./custom/js/calender_view');
