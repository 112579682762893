import {
  minTabletWidth,
  PUBLICATION_LISTING_PAGE,
  economistDetailsPage,
} from './utils';

import { getAutoSearchResults } from './apis';

import {
  buildStructure,
  showAutoSearch,
  showMoreLessAutoSuggestAccordian,
} from './auto_suggest_search';

function searchInputPanel(ele, parent) {
  return ele.parents(parent);
}

function searchInputField(ele, parent) {
  return ele.parent(parent);
}

function hamburgerExpandState() {
  setTimeout(function () {
    if (jQuery('body').hasClass('modal-open')) {
      jQuery('.btn.megamenu-hamburger-icon').attr('aria-expanded', true);
    } else {
      jQuery('.btn.megamenu-hamburger-icon').attr('aria-expanded', false);
    }
  }, 200);
}

function windowScrollFunction() {
  const windowSize = jQuery(window).width();
  const bodyClass = jQuery('body').hasClass('modal-open');
  if (windowSize >= minTabletWidth && !bodyClass) {
    if (jQuery(window).scrollTop() > 62) {
      jQuery('.sticky-nav-wrap, .auto-suggest-search').addClass('sticky-nav-fixed');
      jQuery('.empty-sticky-nav-wrap').removeClass('d-none');
    } else {
      jQuery('.sticky-nav-wrap, .auto-suggest-search').removeClass('sticky-nav-fixed');
      jQuery('.empty-sticky-nav-wrap').addClass('d-none');
    }
  } else if (windowSize < minTabletWidth && !bodyClass) {
    if (jQuery(window).scrollTop() > 50) {
      jQuery('.sticky-nav-wrap, .auto-suggest-search').addClass('sticky-nav-fixed');
      jQuery('.empty-sticky-nav-wrap').removeClass('d-none');
    } else {
      jQuery('.sticky-nav-wrap, .auto-suggest-search').removeClass('sticky-nav-fixed');
      jQuery('.empty-sticky-nav-wrap').addClass('d-none');
    }
  }
}

async function onSearchChange() {
  let oldSearchValue = '';

  const delay = (function () {
    let timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  }(jQuery));

  jQuery('.sticky-nav-wrap').on('keyup', '#search-input-text-box', async function onChangeSearch(e) {
    const code = e.which;
    if (code === 13) {
      window.location.href = `${window.location.origin}/${PUBLICATION_LISTING_PAGE}/?keyword=${e.target.value}`;
    }

    delay(async function () {
      let searchValue = e.target.value;

      if (oldSearchValue !== searchValue && searchValue.length >= 2) {
        let esResults = [];
        try {
          esResults = await getAutoSearchResults(searchValue);
        } catch (err) {
          console.error(err); // eslint-disable-line
        }
        if (!_.isEmpty(esResults) && _.has(esResults, 'responses')) {
          buildStructure(esResults.responses);
          showAutoSearch(true);
          jQuery('.empty-sticky-nav-wrap').addClass('d-none');
          jQuery('#search-input-text-box').on('keydown', function (event) {
            if (((event.keyCode === 9) || (event.which === 9)) && (searchValue !== '')) {
              if (!event.shiftKey) {
                searchValue = '';
                jQuery('#autoSuggestSearchContainer').removeClass('d-none');
                jQuery('#autoSuggestSearchContainer .container').attr('tabindex', '0');
                // jQuery('#autoSuggestSearchContainer .container').focus();
                jQuery('#auto-sugg-close').attr('tabindex', '0').focus();
                jQuery('.search-input').addClass('search-input-expand');
                jQuery('.search-input-text').addClass('search-input-expand');
                jQuery('.search-input').next('.header-menu-list').addClass('header-menu-list-hide');
                jQuery('.search-input').find('.cloes-auto-suggest').addClass('cloes-auto-suggest-active');
                jQuery('.search-input-text').find('.enter-btn').addClass('enter-btn-active');
              }
              if (event.shiftKey) {
                searchValue = '';
                jQuery('#autoSuggestSearchContainer').removeClass('d-none');
                jQuery('.search-input').addClass('search-input-expand');
                jQuery('.search-input-text').addClass('search-input-expand');
                jQuery('.search-input').next('.header-menu-list').addClass('header-menu-list-hide');
                jQuery('.search-input').find('.cloes-auto-suggest').addClass('cloes-auto-suggest-active');
                jQuery('.search-input-text').find('.enter-btn').addClass('enter-btn-active');
                jQuery('.most-often-results-container .result-wrap:last a').focus();
              }
            }
          });
          e.preventDefault();
        }
      } else if (searchValue.length < 2) {
        showAutoSearch(false);
        windowScrollFunction();
      }
      oldSearchValue = searchValue;
    }, 400);
  });
}

function onBlurOfSearch() {
  let hide = true;
  jQuery('body').on('click', function (e) {
    let notOnTooltip = true;
    const targetEle = jQuery(e.target);
    // prevent the close click on the tooltip.
    if (targetEle.parents('.search-input-text').length || targetEle.parents('#autoSuggestSearchContainer').length) {
      notOnTooltip = false;
    }
    if (hide && notOnTooltip) {
      const inputEle = jQuery('#search-input-text-box');
      searchInputPanel(inputEle, '.search-input')
        .removeClass('search-input-expand');
      searchInputField(inputEle, '.search-input-text')
        .removeClass('search-input-expand');
      searchInputPanel(inputEle, '.search-input').next('.header-menu-list')
        .removeClass('header-menu-list-hide');
      searchInputPanel(inputEle, '.search-input').find('.cloes-auto-suggest')
        .removeClass('cloes-auto-suggest-active');
      searchInputField(inputEle, '.search-input-text').find('.enter-btn')
        .removeClass('enter-btn-active');
      showAutoSearch(false);
      inputEle.val('');
    }
    hide = true;
    hamburgerExpandState(e);
  });
}

jQuery(document).ready(async function () {
  showMoreLessAutoSuggestAccordian();
  const inputEle = jQuery('#search-input-text-box');
  inputEle.click(function () {
    searchInputPanel(inputEle, '.search-input')
      .addClass('search-input-expand');
    searchInputField(inputEle, '.search-input-text')
      .addClass('search-input-expand');
    searchInputPanel(inputEle, '.search-input').next('.header-menu-list')
      .addClass('header-menu-list-hide');
    searchInputPanel(inputEle, '.search-input').find('.cloes-auto-suggest')
      .addClass('cloes-auto-suggest-active');
    searchInputField(inputEle, '.search-input-text').find('.enter-btn')
      .addClass('enter-btn-active');
  });
  inputEle.val('');

  onBlurOfSearch();

  jQuery('.btn-link').attr('tabindex', '0');

  jQuery('#search-input-text-box').keydown(function (e) {
    if ((e.keyCode === 9) || (e.which === 9)) {
      e.preventDefault();
      searchInputPanel(inputEle, '.search-input').addClass('search-input-expand');
      searchInputField(inputEle, '.search-input-text').addClass('search-input-expand');
      searchInputPanel(inputEle, '.search-input').next('.header-menu-list').addClass('header-menu-list-hide');
      searchInputPanel(inputEle, '.search-input').find('.cloes-auto-suggest').addClass('cloes-auto-suggest-active');
      searchInputField(inputEle, '.search-input-text').find('.enter-btn').addClass('enter-btn-active');
      jQuery('#auto-sugg-close').attr('tabindex', '0').focus();
      if ((e.keyCode === 27) || (e.which === 27)) {
        jQuery('#search-input-text-box').focus();
        jQuery('.search-input').removeClass('search-input-expand');
        jQuery('.search-input').removeClass('search-input-expand');
        jQuery('.search-input').next('.header-menu-list').removeClass('header-menu-list-hide');
        jQuery('.search-input').find('.cloes-auto-suggest').removeClass('cloes-auto-suggest-active');
        jQuery('.search-input-text').find('.enter-btn').removeClass('enter-btn-active');
      }
      if (e.shiftKey) {
        if (jQuery('#autoSuggestSearchContainer').hasClass('d-none')) {
          jQuery('.search-input').removeClass('search-input-expand');
          jQuery('.search-input').removeClass('search-input-expand');
          jQuery('.search-input').next('.header-menu-list').removeClass('header-menu-list-hide');
          jQuery('.search-input').find('.cloes-auto-suggest').removeClass('cloes-auto-suggest-active');
          jQuery('.search-input-text').find('.enter-btn').removeClass('enter-btn-active');
          jQuery('.btn.megamenu-hamburger-icon').focus();
        } else {
          searchInputPanel(inputEle, '.search-input').addClass('search-input-expand');
          searchInputField(inputEle, '.search-input-text').addClass('search-input-expand');
          searchInputPanel(inputEle, '.search-input').next('.header-menu-list').addClass('header-menu-list-hide');
          searchInputPanel(inputEle, '.search-input').find('.cloes-auto-suggest').addClass('cloes-auto-suggest-active');
          searchInputField(inputEle, '.search-input-text').find('.enter-btn').addClass('enter-btn-active');
          jQuery('.most-often-results-container .result-wrap:last a').focus();
        }
      }
    }
  });
  jQuery('#autoSuggestSearchContainer').keydown(function (e) {
    if ((e.keyCode === 27) || (e.which === 27)) {
      jQuery('#search-input-text-box').focus();
      jQuery('.search-input').removeClass('search-input-expand');
      jQuery('.search-input').removeClass('search-input-expand');
      jQuery('.search-input').next('.header-menu-list').removeClass('header-menu-list-hide');
      jQuery('.search-input').find('.cloes-auto-suggest').removeClass('cloes-auto-suggest-active');
      jQuery('.search-input-text').find('.enter-btn').removeClass('enter-btn-active');
    }
  });
  jQuery('#autoSuggestSearchContainer .auto-suggest-body a:first').keydown(function (e) {
    if ((e.keyCode === 9) || (e.which === 9)) {
      if (e.shiftKey) {
        jQuery('.search-input').addClass('search-input-expand');
        jQuery('.search-input').addClass('search-input-expand');
        jQuery('.search-input').next('.header-menu-list').addClass('header-menu-list-hide');
        jQuery('.search-input').find('.cloes-auto-suggest').addClass('cloes-auto-suggest-active');
        jQuery('.search-input-text').find('.enter-btn').addClass('enter-btn-active');
        jQuery('#search-input-text-box').next().next().focus();
      }
    }
  });

  // For accessibility
  const searchBarEle = jQuery('#search-input-text-box');
  // On focus expand the search bar
  searchBarEle.on('focus', function () {
    searchBarEle.trigger('click');
  });

  jQuery('#auto-sugg-close').keydown(function (e) {
    e.preventDefault();
    if ((e.keyCode === 9) || (e.which === 9)) {
      if (jQuery('#autoSuggestSearchContainer').hasClass('d-none')) {
        searchInputPanel(inputEle, '.search-input').removeClass('search-input-expand');
        searchInputField(inputEle, '.search-input-text').removeClass('search-input-expand');
        searchInputPanel(inputEle, '.search-input').next('.header-menu-list').removeClass('header-menu-list-hide');
        searchInputPanel(inputEle, '.search-input').find('.cloes-auto-suggest').removeClass('cloes-auto-suggest-active');
        searchInputField(inputEle, '.search-input-text').find('.enter-btn').removeClass('enter-btn-active');
        jQuery('.menu-text:first a').focus();
      } else {
        jQuery('#autoSuggestSearchContainer .container').attr('tabindex', '0');
        jQuery('#autoSuggestSearchContainer .container').focus();
        jQuery('.search-input').addClass('search-input-expand');
        jQuery('.search-input-text').addClass('search-input-expand');
        jQuery('.search-input').next('.header-menu-list').addClass('header-menu-list-hide');
        jQuery('.search-input').find('.cloes-auto-suggest').addClass('cloes-auto-suggest-active');
        jQuery('.search-input-text').find('.enter-btn').addClass('enter-btn-active');
      }
      if (e.shiftKey) {
        searchInputPanel(inputEle, '.search-input').addClass('search-input-expand');
        searchInputField(inputEle, '.search-input-text').addClass('search-input-expand');
        searchInputPanel(inputEle, '.search-input').next('.header-menu-list').addClass('header-menu-list-hide');
        searchInputPanel(inputEle, '.search-input').find('.cloes-auto-suggest').addClass('cloes-auto-suggest-active');
        searchInputField(inputEle, '.search-input-text').find('.enter-btn').addClass('enter-btn-active');
        jQuery('#search-input-text-box').focus();
      }
    }
    if ((e.keyCode === 13) || (e.which === 13)) {
      jQuery(this).trigger('click');
      jQuery('.search-input-text').attr('tabindex', '0').focus();
    }
  });

  const closeAutoEle = jQuery('.cloes-auto-suggest');
  closeAutoEle.on('click', function () {
    searchInputField(closeAutoEle).find('search-input-text')
      .removeClass('search-input-expand');
  });
  jQuery(window).scroll(function () {
    windowScrollFunction();
  });
  jQuery('.megamenu-hamburger-icon').on('click', function () {
    if (jQuery('.sticky-nav-wrap, .auto-suggest-search').hasClass('sticky-nav-fixed')) {
      jQuery('.sticky-nav-wrap, .auto-suggest-search').removeClass('sticky-nav-fixed');
    }
  });

  // Main navigation page active
  const urlPath = window.location.pathname; // returns the full URL
  jQuery('.menu-text').each(function loopingFunc(indx, value) {
      const menuTextElement = jQuery(value);
      const anchorElement = menuTextElement.find('a'); // Find the anchor tag within menu-text
      const hrefPath = anchorElement.attr('href');
      
      if (urlPath === hrefPath) {
          anchorElement.addClass('page-active');
          anchorElement.attr('aria-selected', 'true');
          menuTextElement.find('.link-attribute-class').attr('role', 'tablist');
          menuTextElement.find('.link-attribute-class a').attr('role', 'tab');
      } else {
          menuTextElement.find('.link-attribute-class').attr('role', 'list');
          menuTextElement.find('.link-attribute-class a').attr('role', 'listitem');
      }
      if (_.includes(urlPath, `/${economistDetailsPage}/`)) {
          jQuery('.menu-text:nth-child(5), .menu-text:nth-child(5) a').addClass('page-active');
      }
  }); 
  await onSearchChange();

  jQuery('.most-often-results-container .result-wrap:last a').on('keydown blur', function (e) {
    if (e.shiftKey && ((e.keyCode === 9) || (e.which === 9))) {
      jQuery(this).prev().focus();
    } else if ((e.keyCode === 9) || (e.which === 9)) {
      e.preventDefault();
      jQuery('#search-input-text-box').focus();
    }
  });

  $('#skip-main').click(function (e) {
    e.preventDefault();
    const skipContentID = $(this).attr('href').slice(1);
    const skipContent = $(`#${skipContentID}`);
    skipContent.next().attr('tabindex', '0').focus();
    skipContent.next().focusout(function () {
      jQuery(this).removeAttr('tabindex');
    });
    $('html,body').animate({
      scrollTop: skipContent.offset().top - 100,
    }, 500);
  });
  // Hamburger close functionality
  jQuery('#megamenuModalCenter .modal-content .close').on('keydown', function (e) {
    e.preventDefault();
    if ((e.keyCode === 9) || (e.which === 9)) {
      jQuery('#megamenuModalCenter .modal-body ul.menu-nav-links li:first a').focus();
      if (e.shiftKey) {
        jQuery('#megamenuModalCenter .modal-body ul.menu-nav-links li:last a').focus();
      }
    }
    if ((e.keyCode === 13) || (e.which === 13)) {
      jQuery(this).trigger('click');
    }
  });
  jQuery('#megamenuModalCenter .modal-body>ul li:first a').on('keydown', function (e) {
    e.preventDefault();
    if ((e.keyCode === 9) || (e.which === 9)) {
      jQuery('#megamenuModalCenter .modal-body ul.menu-nav-links li:nth-child(2) a').focus();
    }
    if ((e.shiftKey) && ((e.keyCode === 9) || (e.which === 9))) {
      jQuery('#megamenuModalCenter .modal-content .close').focus();
    }
    if ((e.keyCode === 13) || (e.which === 13)) {
      jQuery(this).trigger('click');
    }
  });
  $('h1, h2, h3, h4').each(function (i, ele) {
    if ($(ele).text().trim() === '') {
      $(ele).attr('role', 'presentation');
    }
  });
  $('h5, h6').each(function (i, ele) {
    $(ele).attr('role', 'presentation');
  });
});
