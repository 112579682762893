// Add the Utils function and constant that will be used.
import {
  resetStickyPositionTop,
} from './publication_utils';

export const minTabletWidth = 768;

export const minDesktopWidth = 992;

export const minTabLandscapeWidth = 1024;

export const ECONOFACTS_LISTING_PAGE = 'econofacts';
export const economistDetailsPage = 'economist-details';
export const economistListingPage = 'our-economists';

export const PUBLICATION_LISTING_PAGE = 'publications';
export const NEWS_COMMENTARY_LISTING = 'news-commentary';
export const HISTORICAL = 'historical';
export const PUBLICATION_DETAIL = 'detail';
export const PUBLICATION_PDF_URL = 'media/pdf/{publication.id}.pdf';
export const CALENDAR_FLAGS_URL = '/media/flags/';

export const CALENDAR_SLUG = 'calendar';

export const BEDateFormat = 'MM/DD/YYYY';
export const countryOrder = ['ca', 'us', 'international'];
export const typeOrder = ['multiple', 'individual'];
export const defaultCalenderType = 'ECON';
export const monthFormat = 'MMMM YYYY';
export const DEFAULT_ECONOMIST_IMAGE = '/static/assets/images/default-economist.png';

export const ECONOFACTS_PUBLICATIONS_API = `${window.location.origin}/econofacts/getPublications/`;
export const PUBLICATIONS_LIST_API = `${window.location.origin}/en/publications/getPublications/`;
export const BOOKMARK_LIST_API = `${window.location.origin}/en/publications/getBookmarkData/`;

export const DEFAULT_NUMBER_OF_MONTHS = 6;

export const defaultFilters = {
  calenderType: 'ECON',
  countryType: ['USA', 'CAN', 'international'],
  startDate: moment().format(BEDateFormat),
  endDate: moment().add(DEFAULT_NUMBER_OF_MONTHS, 'M').endOf('month').format(BEDateFormat),
};

export function isTab() {
  const currentWidth = $(window).width();
  if (currentWidth >= minTabletWidth && currentWidth < minDesktopWidth) {
    return true;
  }
  return false;
}

export function isMobile() {
  const currentWidth = $(window).width();
  if (currentWidth < minTabletWidth) {
    return true;
  }
  return false;
}

export function isDesktop() {
  const currentWidth = $(window).width();
  if (currentWidth >= minDesktopWidth) {
    return true;
  }
  return false;
}

export function isEconofactsPage() {
  if (window.location.href.indexOf('econofacts') > -1) {
    return true;
  }

  return false;
}

export function isPublicationListingPage() {
  const isPublication = window.location.href.indexOf(PUBLICATION_LISTING_PAGE) > -1;
  const isNewsCommentary = window.location.href.indexOf(NEWS_COMMENTARY_LISTING) > -1;
  const isDetails = window.location.href.indexOf(PUBLICATION_DETAIL) > -1;

  if ((isPublication || isNewsCommentary) && !isDetails) {
    return true;
  }

  return false;
}

export function getDataForDate(object, date) {
  const dateFormated = moment(date).format(BEDateFormat);
  if (_.has(object, dateFormated)) {
    return object[dateFormated];
  }
  return {};
}

export function cloneTemplate(template) {
  const element = jQuery(`.template.d-none .${template}`).clone().removeClass(template);
  return element;
}

export function cloneChildTemplate(childTemplate) {
  const element = jQuery(`.${childTemplate}`).clone();
  return element;
}

export function flagURL(code) {
  // Only for international CA US
  if (code === 'us') {
    return `${CALENDAR_FLAGS_URL}USA.png`;
  }
  if (code === 'ca') {
    return `${CALENDAR_FLAGS_URL}CAN.png`;
  }
  return `${CALENDAR_FLAGS_URL}international.png`;
}

export function utcTime(time) {
  // Since time is converted from BE based on user timezone, time is not converted.
  // This function is kept if incase we have to change the time again.
  return time;
  // return moment(time, 'LT').utc().format('LT');
}

export function getQueryStringValue(key) {
  const searchParm = decodeURIComponent(window.location.search.replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace('/[.+*]/g', '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'));
  const spans = document.createElement('span');
  spans.innerHTML = searchParm;
  return spans.textContent || spans.innerText;
}

export function economistDetailsRedirection(element, targetedElement) {
  jQuery(targetedElement).on('click', function () {
    const parentElement = jQuery(this).closest(element);
    const economistid = parentElement.data('economistid');
    if (economistid) {
      window.location.href = `${window.location.origin}/${economistListingPage}/${economistDetailsPage}/${economistid}/`;
    }
  });
}

export function ourEconomistRedirection(element) {
  jQuery(element).on('click', function () {
    window.location.href = `${window.location.origin}/${economistListingPage}/`;
  });
  // Trigger click on element when enter is hit from the keyboard
  jQuery(element).on('keyup', function (event) {
    if ((event.keyCode === 13) || (event.which === 13)) {
      jQuery(element).click();
    }
  });
}

export function horizontalResize(callback) {
  const windowObj = jQuery(window);
  let prevWidth = windowObj.width();
  windowObj.on('resize', function () {
    const width = windowObj.width();
    if (width !== prevWidth) {
      callback();
    }
    prevWidth = width;
  });
}

// using jQuery
export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = jQuery.trim(cookies[i]);
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function replaceVariable(htmlContent, variable, val) {
  let value = val;
  if (typeof htmlContent === 'undefined') {
    return '';
  }

  if (typeof value === 'undefined') {
    value = '';
  }

  const reg = new RegExp(variable, 'g');
  return htmlContent.replace(reg, value);
}

export function getPublicationLink(id, productID, historicalPublication) {
  if (historicalPublication === true) {
    return `${window.location.origin}/${PUBLICATION_LISTING_PAGE}/${HISTORICAL}/${id}`;
  }

  const keyword = getQueryStringValue('keyword');
  let keywordQuery = '';

  if (keyword !== '') {
    keywordQuery = `?keyword=${keyword}`;
  }

  return `${window.location.origin}/${PUBLICATION_LISTING_PAGE}/${PUBLICATION_DETAIL}/${productID}/${keywordQuery}`;
}

export function getEconomistLink(id) {
  return `${window.location.origin}/${economistListingPage}/${economistDetailsPage}/${id}/`;
}

export function getEconomistImage(image) {
  if (typeof image !== 'undefined') {
    return image;
  }
  return DEFAULT_ECONOMIST_IMAGE;
}

export function changePageUrl(url) {
  window.history.replaceState('', '', url);
}

export function getQueryString(filters) {
  let searchQuery = '';
  let count = 0;

  _.forEach(filters, function (value, key) {
    searchQuery += `${count === 0 ? '?' : '&'}${key}=${value}`;
    count += 1;
  });

  return searchQuery;
}

export function parseQueryString(queryString) {
  const params = {};

  // Split into key/value pairs
  const queries = queryString.split('&');

  _.map(queries, function (value) {
    const temp = value.split('=');
    params[temp[0]] = temp[1]; //eslint-disable-line
  });

  return params;
}

export function parseDateToURLDate(date) {
  return date.replace(/\//g, '-');
}

export function parseURLDateToDate(date) {
  return date.replace(/-/g, '/');
}

export function getEnv() {
  return process.env.NODE_ENV.toLowerCase();
}

// Is Tablet device
export function isTabletDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
  return isTablet;
}

export function selectCurrentActiveDay() {
  $('.datepicker-days .day').attr('aria-current', false);
  $('.datepicker-days .day.active').attr('aria-current', true);
}
export function calendarMonthExpandState() {
  jQuery('.datepicker-switch').on('click blur', function (e) {
    setTimeout(function () {
      if (jQuery(e.target).hasClass('chevron-up')) {
        jQuery(e.target).attr('aria-expanded', true);
      } else {
        jQuery(e.target).attr('aria-expanded', false);
      }
    }, 300);
  });
}

export function calenderActiveRows() {
  if (jQuery('.datepicker-days .table-condensed tr .old.day').parent().children(':not(.old)').length === 0) {
    jQuery('.datepicker-days .table-condensed tr .old.day').parent().attr({ 'aria-hidden': true, role: 'none' });
  }
  jQuery('.datepicker-days .table-condensed th:not(.dow)').parent().attr({ 'aria-hidden': true, role: 'none' });
  jQuery('.datepicker .datepicker-title').attr({ 'aria-hidden': true, role: 'none' });
  jQuery('.datepicker tfoot tr th').attr({ 'aria-hidden': true, role: 'none' });
  jQuery('.datepicker-switch').parent().removeAttr('aria-hidden');
  jQuery('.datepicker-switch').parent().removeAttr('role');
  if (!$('.datepicker-days table tbody tr:last-child td').is(':visible')) {
    $('.datepicker-days table tbody tr:last-child').attr({ 'aria-hidden': true, role: 'none' });
  }
  $('th.datepicker-switch').parent().attr({ 'aria-hidden': true, role: 'none' });
}

// Page loader before page loads
jQuery(window).on('load', function () {
  jQuery('.page-loader-mask').addClass('page-loader-hide');
});

// This below function is when the modal is open the postion to the body is set to fixed
// Which will make the the page to scroll to top (0, 0), we can still use position inherit and
// overflow hidden but that will not work in iOS Tablet to fix this on modal open current window Y
// position is saved Body is set to fixed and on close its scroll back to the saved version.

let currentWindowPos = 0;
jQuery('body').on('show.bs.modal', '.modal:not(#calenderContainer, #filterModal)', () => {
  if (isTab() || isMobile()) {
    currentWindowPos = window.pageYOffset;
  }
});
jQuery('body').on('hide.bs.modal', '.modal:not(#calenderContainer, #filterModal)', () => {
  if (isTab() || isMobile()) {
    window.scrollTo(0, currentWindowPos);
  }
});
jQuery('body').on('click', '.modal-content', (e) => {
  if (e.target) {
    if (!(jQuery(e.target).attr('data-dismiss') === 'modal' || jQuery(e.target).hasClass('submit-author'))) {
      jQuery('body').addClass('no-body-scroll');
    }
  }
});

jQuery('body').on('hidden.bs.modal', '.modal', () => {
  jQuery('body').removeClass('no-body-scroll');
  if (jQuery('#sidebar').length) {
    resetStickyPositionTop();
  }
});
