import { getEnv } from './utils';

/* eslint-disable comma-dangle */
export const autoSuggestPublication = {
  size: 6,
  sort: [
    {
      _score: 'desc',
    },
    {
      'title.raw': 'asc',
    },
  ],
  query: {
    bool: {
      should: [
        {
          query_string: {
            default_field: 'title',
            query: 'SEARCH_TEXT',
            boost: 20
          }
        },
        {
          query_string: {
            default_field: 'body',
            query: 'SEARCH_TEXT',
            boost: 10
          }
        },
        {
          nested: {
            path: 'industry',
            query: {
              query_string: {
                default_field: 'industry.name_first_word',
                query: 'SEARCH_TEXT',
                boost: 10
              }
            }
          }
        },
        {
          nested: {
            path: 'industry',
            query: {
              query_string: {
                default_field: 'industry.name',
                query: 'SEARCH_TEXT',
                boost: 8
              }
            }
          }
        },
        {
          nested: {
            path: 'economists',
            query: {
              query_string: {
                default_field: 'economists.first_name',
                query: 'SEARCH_TEXT',
                boost: 5
              }
            }
          }
        },
        {
          nested: {
            path: 'economists',
            query: {
              query_string: {
                default_field: 'economists.last_name',
                query: 'SEARCH_TEXT',
                boost: 5
              }
            }
          }
        }
      ],
      must_not: [
        {
          bool: {
            must: [
              {
                range: {
                  display_datetime: {
                    lt: '2021-01-01T00:00:00'
                  }
                }
              },
              {
                nested: {
                  path: 'industry',
                  query: {
                    term: {
                      'industry.bm_id': '17'
                    }
                  }
                }
              }
            ]
          }
        },
        {
          range: {
            display_datetime: {
              lt: '2019-07-01T00:00:00',
            }
          }
        }
      ]
    }
  }
};

export const autoSuggestCalendar = {
  size: 6,
  sort: [
    {
      event_start_time: 'asc',
    },
  ],
  query: {
    bool: {
      must: [
        {
          bool: {
            should: [
              {
                query_string: {
                  default_field: 'event_name',
                  query: 'SEARCH_TEXT',
                  boost: 12
                }
              },
              {
                query_string: {
                  default_field: 'keywords',
                  query: 'SEARCH_TEXT',
                  boost: 8
                }
              },
              {
                query_string: {
                  default_field: 'country',
                  query: 'SEARCH_TEXT',
                  boost: 5
                }
              },
              {
                query_string: {
                  default_field: 'alpha2_country_code',
                  query: 'SEARCH_TEXT',
                  boost: 4
                }
              },
              {
                query_string: {
                  default_field: 'alpha3_country_code',
                  query: 'SEARCH_TEXT',
                  boost: 4
                }
              }
            ]
          }
        },
        {
          range: {
            publish_date_time: {
              lte: moment.utc(new Date()).format('YYYY-MM-DDTkk:mm:ss'),
            }
          }
        },
        {
          range: {
            event_start_time: {
              gte: moment.utc(new Date()).format('YYYY-MM-DDTkk:mm:ss'),
            }
          }
        }
      ]
    }
  }
};
/* eslint-enable comma-dangle */

export function getSearchTypeaheadResults(term = '') {
  let escapedTerm = '';
  let charcterQuotesLength = 0;
  const character = '"';
  _.map(term, (string) => {
    if (string === character) charcterQuotesLength += 1;
  });
  if (charcterQuotesLength % 2) {
    const lastCharacterPos = term.lastIndexOf(character);
    escapedTerm = `${term.slice(0, lastCharacterPos)}${term.slice(lastCharacterPos + 1)}`;
  } else {
    escapedTerm = term;
  }
  escapedTerm = escapedTerm.replace(/\\/g, '\\\\\\\\');
  escapedTerm = escapedTerm.replace(/"/g, '\\"');
  const specialChars = [
    '+',
    '-',
    '!',
    '(',
    ')',
    ':',
    '^',
    '[',
    ']',
    '~',
    '{',
    '}',
    '*',
    '?',
    '/',
  ];
  _.map(specialChars, (char) => {
    const regex = new RegExp(`(\\${char})`, 'g');
    escapedTerm = escapedTerm.replace(regex, '\\\\$1');
    return null;
  });
  return escapedTerm;
}

export function getElasticSearchJSON(searchText) {
  const stringifiedPublication = JSON.stringify(autoSuggestPublication);
  const stringifiedCalendar = JSON.stringify(autoSuggestCalendar);
  const escapedTerm = getSearchTypeaheadResults(searchText);
  const env = getEnv();
  const publicationIndex = `publication_${env}`;
  const calendarEventsIndex = `calendar_events_${env}`;

  const query = `{"index":"${publicationIndex}"}\n${stringifiedPublication}\n{"index":"${calendarEventsIndex}"}\n${stringifiedCalendar}\n`;
  // eslint-disable-next-line prefer-regex-literals
  return _.replace(query, new RegExp('SEARCH_TEXT', 'g'), escapedTerm);
}
