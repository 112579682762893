// Calender List View Class

export default class CalenderListView {
  constructor() {
    this.eventsData = '';
  }

  setEvents(htmlData) {
    this.eventsData = htmlData;
  }

  renderEventsList() {
    jQuery('.calendar-list-column').html(this.eventsData);
  }
}
