import {
  isMobile,
  ourEconomistRedirection,
} from './utils';

import { PublicationView } from './publication_utils';

let discriptionData = '';

function trimDiscription(anchorTag) {
  const economistDiscriptionElement = jQuery('.economist-discription-content');
  if (isMobile()) {
    if (discriptionData.length < 275) {
      anchorTag.hide();
    } else if (anchorTag.text() === '(less)') {
      economistDiscriptionElement.html(discriptionData);
    } else {
      const trimmedData = _.truncate(_.clone(discriptionData), {
        length: 275,
        separator: ' ',
      });
      economistDiscriptionElement.html(trimmedData);
    }
  } else {
    economistDiscriptionElement.html(discriptionData);
  }
}

function showMoreHide() {
  // This is to handle the Showmore and showless for the economist details
  const anchorTag = jQuery('.show-more-span').find('a');
  trimDiscription(anchorTag);
  jQuery('.show-more-span').on('click', function showMoreLess() {
    jQuery(this).toggleClass('show-less');
    if (anchorTag.text() === '(less)') {
      anchorTag.text('(more)');
    } else {
      anchorTag.text('(less)');
    }
    trimDiscription(anchorTag);
  });
}

function screenResize() {
  const anchorTag = jQuery('.show-more-span').find('a');
  trimDiscription(anchorTag);
}

function onBackToEconomistListClick() {
  ourEconomistRedirection('.back-to-economists');
}

export default function getEconomistFilters() {
  return {
    economist_id: jQuery('.economist-profile-discription').data('id'),
    limit_per_page: jQuery('.publication-result-card-block-wrap').data('limit'),
  };
}

export function disableLinkForPrimaryAuthor(economistID) {
  // disable link for primary author and enable for rest of them
  jQuery(`
    .publication-author[data-id=${economistID}] .author-image-name,
    .publication-author[data-id=${economistID}] .author-name-text-link,
    .author-name-wrap[data-id=${economistID}] .author-name a,
    .economist-preview-left[data-id=${economistID}] .author-preview-image-name
  `).css('pointer-events', 'none');

  // Hide the arrow for primary author because it won't be clickable
  jQuery(`.author-name-wrap[data-id=${economistID}] .arrow-link-detail`).hide();
}

jQuery(document).ready(function () {
  // Recent releases function
  if (jQuery('.economist-profile-discription').length) {
    discriptionData = jQuery('.economist-discription-content').html();
    showMoreHide();
    onBackToEconomistListClick();
    jQuery(window).on('resize', screenResize);

    // Initialise PublicationView Class
    const publicationView = new PublicationView();

    // Set the section
    publicationView.setSection('economist_profile');

    const filters = getEconomistFilters();
    publicationView.setFilters(filters);

    // Enable sort feature
    publicationView.sortFeature();

    publicationView.renderPublicationsListing().then(function (json) {
      const economistID = jQuery('.economist-profile-discription').data('id');
      disableLinkForPrimaryAuthor(economistID);

      const pubIDs = [];
      _.forEach(json.hits.hits, function (value) {
        pubIDs.push(value._id);
      });

      if (json.hits.total.value > filters.limit_per_page) {
        publicationView.showLoadMoreAlways = true;
      }

      publicationView.setFilter('publication_id', pubIDs);
    });
  }

  jQuery('.recent-publications-header .recent-publications').attr({ 'aria-level': 2, role: 'heading' });
});
