import 'babel-polyfill';
// import StickySidebar from 'sticky-sidebar/dist/sticky-sidebar';

import {
  replaceVariable,
  getEconomistLink,
  getEconomistImage,
  getPublicationLink,
  PUBLICATIONS_LIST_API,
  ECONOFACTS_PUBLICATIONS_API,
  BEDateFormat,
  // isEconofactsPage,
  minTabLandscapeWidth,
  isDesktop,
  isTab,
} from './utils';

import { disableLinkForPrimaryAuthor } from './economist_profile';

import { togglePublicationBookMarks } from './bookmark';

import { getPublicationsJsonData } from './apis';

const publicationCard = jQuery('#publication-card-template').clone();
const economistHTML = jQuery('#author-info-modal-template').clone().html();
const noPublicationsHTML = jQuery('#no-publications-template').clone().html();
const pubHTML = publicationCard.html();
window.PageNumber = 1;

let filterSortBy = 'desc';
// let filterSort = 'release_datetime';
let noOfPublicationsRendered = 0;

const showLess = {
  config: {
    showLessClick: jQuery('.btn.show-less-filter'),
  },
  setup: function showLessToggle() {
    /* Hide and show image and description text from the publication card */
    if (jQuery(this).find('span:first-child').text() === 'Show Less Detail') {
      jQuery(this).find('span:first-child').text('Show More Detail');
      jQuery('.author-info-modal').addClass('author-info-modal-less');
    } else {
      jQuery(this).find('span:first-child').text('Show Less Detail');
      jQuery('.author-info-modal').removeClass('author-info-modal-less');
    }
    jQuery(this).find('.drop-down-icon').toggleClass('down');
    jQuery('.publication-result-card .publication-author img, .publication-result-card .publication-description').toggleClass('d-none');
  },
  init: function showLessToggler() {
    const currentElement = showLess.config.showLessClick;
    if (currentElement.length) {
      currentElement.on('click', showLess.setup);
    }
  },
};

export function getPageNumber() {
  return window.PageNumber;
}

export function resetPageNumber() {
  window.PageNumber = 1;
}

export function incrementPageNumber() {
  window.PageNumber += 1;
}

function setNoOfPublicationsRendered(value) {
  noOfPublicationsRendered = value;
}

function getNoOfPublicationsRendered() {
  return noOfPublicationsRendered;
}

export function showNoPublications(section) {
  let publicationSection = '.publication-result-card-block-wrap';

  if (typeof section !== 'undefined') {
    publicationSection = section;
  }

  jQuery(publicationSection).html(noPublicationsHTML);
}

export function publicationError() {
  showNoPublications('.publication-result-card-block-wrap');
  jQuery('.load-more-publications').addClass('d-none');
}

export function loaderBegins() {
  jQuery('.page-loader.publication-loader').removeClass('d-none').addClass('d-block');
}

export function loaderEnds() {
  // jQuery('#main-content #content').css('min-height', '');
  jQuery('.page-loader.publication-loader').removeClass('d-block').addClass('d-none');
}

// --------------------------------

let isTopSticky = false;
let isBottomSticky = false;
let isBottomFooterSticky = false;
let isTopProgressiveSticky = false;
let lastScrollTop = 0;

function scrollDownFromCurrentposition() {
  jQuery('#sidebar .sidebar__inner').css({
    position: 'absolute',
    top: `${window.pageYOffset - 200}px`,
    bottom: 'auto',
  });
  isTopSticky = false;
}

function scrollUpFromCurrentposition() {
  const sideBarEle = jQuery('#sidebar .sidebar__inner').get(0);
  const sideBarParentEle = jQuery('#sidebar').get(0);
  if (sideBarEle && sideBarParentEle) {
  /*  Commenting this code as it is adding improper bottom value to sidebar */
    // const si = sideBarParentEle.getBoundingClientRect();
    // jQuery('#sidebar .sidebar__inner').css({
    //   position: 'absolute',
    //   bottom: `${si.bottom - window.innerHeight + 30}px`,
    //   top: 'auto',
    // });
    jQuery('#sidebar .sidebar__inner').css({
      position: 'absolute',
      top: '0px',
    });
    isBottomSticky = false;
  }
}

function removeSticky() {
  const mainContentEle = jQuery('#main-content').get(0);
  if (mainContentEle) {
    if (mainContentEle.getBoundingClientRect().top >= 140) {
      jQuery('#sidebar .sidebar__inner').css({
        position: '',
        top: '',
        bottom: '',
      });
      isTopSticky = false;
      isTopProgressiveSticky = false;
    }
  }
}

function topSticky() {
  const sideBarEle = jQuery('#sidebar .sidebar__inner').get(0);
  if (sideBarEle) {
    if (sideBarEle.getBoundingClientRect().top >= 140) {
      jQuery('#sidebar .sidebar__inner').css({
        position: 'fixed',
        top: '140px',
        bottom: 'auto',
      });
      isTopSticky = true;
      isTopProgressiveSticky = true;
      isBottomFooterSticky = false;
    }
  }
}

function topProgressiveSticky() {
  const sideBarEle = jQuery('#sidebar .sidebar__inner').get(0);
  if (sideBarEle) {
    if (sideBarEle.getBoundingClientRect().top <= 140) {
      jQuery('#sidebar .sidebar__inner').css({
        position: 'fixed',
        top: '140px',
        bottom: 'auto',
      });
      isTopProgressiveSticky = true;
    }
  }
}

function bottomFooterSticky() {
  const sideBarEle = jQuery('#sidebar .sidebar__inner').get(0);
  const footerEle = jQuery('footer').get(0);
  if (sideBarEle && footerEle) {
    const footerBoundingEle = footerEle.getBoundingClientRect();
    if (sideBarEle.getBoundingClientRect().bottom >= (footerBoundingEle.top - 30)) {
      jQuery('#sidebar .sidebar__inner').css({
        position: 'absolute',
        bottom: '0',
        top: 'auto',
      });
      isBottomSticky = false;
      isBottomFooterSticky = true;
    }
  }
}

function bottomSticky() {
  const sideBarEle = jQuery('#sidebar .sidebar__inner').get(0);
  const windowHeight = window.innerHeight;
  if (sideBarEle) {
    if (sideBarEle.getBoundingClientRect().bottom <= (windowHeight - 30)) {
      jQuery('#sidebar .sidebar__inner').css({
        position: 'fixed',
        bottom: '30px',
        top: 'auto',
      });
      jQuery('#sidebar .sidebar__inner').css('z-index', '1');
      isBottomSticky = true;
      isBottomFooterSticky = false;
    }
  }
}

function setMinHeightToContent() {
  const minHeight = jQuery('#sidebar .sidebar__inner').height();
  jQuery('#main-content #content').css('min-height', `${minHeight}px`);
}

function checkIfSidebarLowHeight() {
  const sidebarHeight = jQuery('#sidebar .sidebar__inner').height();
  const windowHeight = window.innerHeight - 150;
  let lowFilterHeight = false;
  if (sidebarHeight < windowHeight) {
    lowFilterHeight = true;
  }
  return lowFilterHeight;
}

function scrollTopIfNoData() {
  const listEle = jQuery('.publication-result-card-block-wrap').get(0);
  if (listEle) {
    const listObj = listEle.getBoundingClientRect();
    if (listObj.bottom < 300) {
      window.scrollTo(0, 0);
      removeSticky();
    }
  }
}

export function resetStickyPositionTop() {
  setMinHeightToContent();
  lastScrollTop = jQuery(this).scrollTop();
  const contentEle = jQuery('#main-content #content').get(0);
  const footerEle = jQuery('footer').get(0);
  const windowHeight = window.innerHeight;
  if (contentEle && footerEle) {
    const contentRectObj = contentEle.getBoundingClientRect();
    const footerRectObj = footerEle.getBoundingClientRect();
    if (contentRectObj.top >= 140) {
      removeSticky();
    }
    if (contentRectObj.top <= 140 && windowHeight <= footerRectObj.top) {
      topProgressiveSticky();
      topSticky();
    }
    if (contentRectObj.top <= 140 && windowHeight >= footerRectObj.top) {
      bottomFooterSticky();
    }
  }
}

export function resetStickyPositionCalendarTop() {
  resetStickyPositionTop();
}

function resetStickyPositionOnLoadMore() {
  setMinHeightToContent();
  const contentEle = jQuery('#main-content #content').get(0);
  const footerEle = jQuery('footer').get(0);
  const windowHeight = window.innerHeight;
  if (contentEle && footerEle) {
    const elementRectObj = contentEle.getBoundingClientRect();
    const footerRectObj = footerEle.getBoundingClientRect();
    if (elementRectObj.top <= 140 && (windowHeight) >= footerRectObj.top) {
      bottomFooterSticky();
    } else if (isBottomFooterSticky && checkIfSidebarLowHeight()) {
      topSticky();
    } else if (isBottomFooterSticky) {
      jQuery('#sidebar .sidebar__inner').css({
        position: 'fixed',
        bottom: '30px',
        top: 'auto',
      });
      jQuery('#sidebar .sidebar__inner').css('z-index', '1');
      isBottomSticky = true;
      isBottomFooterSticky = false;
    }
  }
}

export function stickyFilterSection() {
  // setting min height to the other non stick element
  setMinHeightToContent();
  if (isDesktop() || isTab()) {
    jQuery(window).scroll(function () {
      const st = jQuery(this).scrollTop();
      if (st > lastScrollTop) {
        // down
        if (checkIfSidebarLowHeight()) {
          topProgressiveSticky();
          bottomFooterSticky();
        } else {
          bottomSticky();
          if (isTopSticky) {
            scrollDownFromCurrentposition();
          } else {
            bottomFooterSticky();
          }
        }
      } else if (st < lastScrollTop) {
        // up
        if (checkIfSidebarLowHeight()) {
          if (isTopProgressiveSticky) {
            removeSticky();
          }
          if (isBottomFooterSticky) {
            topSticky();
          }
        } else {
          topSticky();
          if (isBottomSticky) {
            scrollUpFromCurrentposition();
          } else {
            removeSticky();
          }
        }
      }
      lastScrollTop = st;
    });
  }
}

export function expandCollapseReset() {
  const onExpandCollapseIssue = jQuery('.publications-table-template [data-toggle="collapse"], .econofacts-table-template [data-toggle="collapse"]');
  _.map(onExpandCollapseIssue, (ele) => {
    const id = jQuery(ele).attr('data-target');
    jQuery(id).on('shown.bs.collapse', function () {
      setMinHeightToContent();
      bottomFooterSticky();
    });
    jQuery(id).on('hidden.bs.collapse', function () {
      setMinHeightToContent();
      bottomFooterSticky();
    });
  });
}

// --------------------------------

// MAIN PUBLICATION TABLE RENDERING Ends
function windowResizeFunction() {
  jQuery(window).resize(function () {
    if (jQuery(window).width() > minTabLandscapeWidth - 1) {
      if (jQuery('.author-modal-mobile').hasClass('show')) {
        jQuery('.author-modal-mobile').modal('hide');
      }
    }

    /* Preview info window hide below tablet */
    if (jQuery(window).width() < minTabLandscapeWidth) {
      jQuery('.preview-info-window').removeClass('d-block').addClass('d-none');
      jQuery('.preview-active').removeClass('preview-active').addClass('preview-inactive');
    }
  });
}

function setPositionForCard(ele) {
  jQuery(ele).parents('.main-publication').find('.left-block').css({
    position: 'relative',
    'z-index': '-1',
  });
}

function removePositionForCard(ele) {
  jQuery(ele).parents('.main-publication').find('.left-block').css({
    position: '',
    'z-index': '',
  });
}

if (jQuery('.publication-result-card-block-wrap').length) {
  // Publication preview
  jQuery('.main-publication-table').on('click', '.preview-icon', function () {
    jQuery(this).parent('.publication-preview').find('.close-icon').focus();
    jQuery('.publication-preview .preview-icon').not(this).removeClass('preview-active').addClass('preview-inactive');
    if (jQuery(this).hasClass('preview-inactive')) {
      jQuery(this).removeClass('preview-inactive').addClass('preview-active');
    } else if (jQuery(this).hasClass('preview-active')) {
      jQuery(this).removeClass('preview-active').addClass('preview-inactive');
    }
    const windowEle = jQuery(this).parent('.publication-preview').find('.preview-info-window');
    if (jQuery(windowEle).hasClass('d-none')) {
      jQuery('.publication-preview').find('.preview-info-window').not(this).removeClass('d-block')
        .addClass('d-none');
      jQuery(this).parent('.publication-preview').find('.preview-info-window').addClass('d-block')
        .removeClass('d-none');
      jQuery(this).parents('.publication-result-card').find('.preview-info-window').focus();
      setPositionForCard(this);
    } else if (jQuery(windowEle).hasClass('d-block')) {
      jQuery(windowEle).find('a:first').focus();
      jQuery(this).parent('.publication-preview').find('.preview-info-window').addClass('d-none')
        .removeClass('d-block');
      removePositionForCard(this);
    }
  });

  // Preview window close
  jQuery('.main-publication-table').on('click', '.close-icon', function () {
    const previewIcon = jQuery(this).parents('.publication-preview').find('.preview-icon');
    if (jQuery(previewIcon).hasClass('preview-inactive')) {
      jQuery(previewIcon).removeClass('preview-inactive').addClass('preview-active');
    } else if (jQuery(previewIcon).hasClass('preview-active')) {
      jQuery(previewIcon).removeClass('preview-active').addClass('preview-inactive');
    }
    const windowEle = jQuery(this).parent('.preview-info-window');
    if (jQuery(windowEle).hasClass('d-none')) {
      jQuery(windowEle).removeClass('d-none').addClass('d-block');
      setPositionForCard(this);
    } else if (jQuery(windowEle).hasClass('d-block')) {
      jQuery(windowEle).removeClass('d-block').addClass('d-none');
      removePositionForCard(this);
      jQuery(this).parents('.publication-preview').find('.preview-icon').focus();
    }
  });
  // Mobile dropdown options
  jQuery('.mobile-filter-dropdown').on('click', function () {
    // Drop down arrow
    jQuery(this).find('.drop-down-icon').toggleClass('down');
    if (jQuery(this).find('.drop-down-icon').hasClass('down')) {
      jQuery(this).attr('aria-expanded', 'true');
    } else {
      jQuery(this).attr('aria-expanded', 'false');
    }
    // Options list
    const optionsEle = jQuery('.mobile-filter-options');
    if (jQuery(optionsEle).hasClass('d-none')) {
      jQuery(optionsEle).removeClass('d-none').addClass('d-block');
    } else if (jQuery(optionsEle).hasClass('d-block')) {
      jQuery(optionsEle).removeClass('d-block').addClass('d-none');
    }
  });

  // Preview Mobile Modal hide on resize
  windowResizeFunction();

  // Show More/Less function
  jQuery(showLess.init);
}

export class PublicationView {
  constructor() {
    this.filters = {
      page: 1,
    };
    this.section = '';
    this.publicationBlock = '';
    this.publicationHtml = '';
    this.publications = [];
    this.showLoadMoreAlways = false;
  }

  setFilter(key, value) {
    this.filters[key] = value;
  }

  setFilters(filters) {
    this.filters = _.assignIn(this.filters, filters);
  }

  getFilters() {
    return this.filters;
  }

  setSection(section) {
    this.section = jQuery(`div[data-publication="${section}"]`);
    this.publicationBlock = this.section.find(
      '.publication-result-card-block-wrap',
    );
  }

  setPublications(publications) {
    this.publications = publications;
  }

  resetPageNumber() {
    this.filters.page = 1;
  }

  resetPublicationsList() {
    // const minHeight = jQuery('#main-content #content').height();
    // jQuery('#main-content #content').css('min-height', `${minHeight}px`);
    setNoOfPublicationsRendered(0);

    jQuery('.load-more-publications, .publication-sell-all-js').addClass('d-none');
    jQuery(this.publicationBlock).html('');
  }

  renderPublicationsOnDOM() {
    this.publicationBlock.append(
      this.publicationHtml,
    );
  }

  buildPublicationsHTML() {
    let publicationTemplateWrap = '';

    publicationCard.removeClass('publication-result-card-template');

    _.map(this.publications, (listItem) => {
      let publicationHTML = pubHTML;
      /* eslint no-underscore-dangle: 0 */
      const publicationDate = listItem._source.release_datetime;
      const publicationDisplayDate = listItem._source.display_datetime;

      let economistsHTML = '';
      let { economists } = listItem._source;
      const sourceData = listItem._source;

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.release_datetime }}',
        moment(publicationDate).format(BEDateFormat),
      );

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.display_datetime }}',
        moment(publicationDisplayDate).format(BEDateFormat),
      );

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.title }}',
        sourceData.title,
      );

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.industry }}',
        sourceData.industry.name,
      );

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.subject }}',
        sourceData.subject,
      );

      let { summary } = sourceData;

      if (sourceData.historical_publication === false) {
        const summaryWithoutTags = sourceData.summary.replace(/(<([^>]+)>)/ig, '');
        summary = summaryWithoutTags.trim();
        summary = _.truncate(summary, {
          length: 180,
          separator: ' ',
        });
      }

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.summary }}',
        summary,
      );

      const publicationLink = getPublicationLink(
        listItem._id,
        listItem._source.product_id,
        sourceData.historical_publication,
      );

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.link }}',
        publicationLink,
      );

      if (publicationLink.indexOf('historical') !== -1) {
        publicationHTML = replaceVariable(
          publicationHTML,
          '{{ publication.target }}',
          '_blank',
        );
      } else {
        publicationHTML = replaceVariable(
          publicationHTML,
          '{{ publication.target }}',
          '',
        );
      }

      // Economists Section

      // If there are no economist to show, then display blank
      if (economists.length === 0) {
        economists = [{
          avatar_url: undefined,
          person_id: '',
          display_name: '',
          email: '',
          phone: '',
          division_name: '',
          designation: '',
        }];

        publicationHTML = replaceVariable(
          publicationHTML,
          '{{ force_hide }}',
          'force-d-none d-none',
        );
      } else {
        publicationHTML = replaceVariable(
          publicationHTML,
          '{{ force_hide }}',
          '',
        );
      }

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.primary_author.image }}',
        getEconomistImage(economists[0].avatar_url),
      );

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.primary_author.person_id }}',
        getEconomistImage(economists[0].person_id),
      );

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.primary_author.display_name }}',
        economists[0].display_name,
      );

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.primary_author.email }}',
        economists[0].email,
      );

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.primary_author.phone }}',
        economists[0].phone,
      );

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.primary_author.division_name }}',
        economists[0].division_name,
      );

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.primary_author.link }}',
        getEconomistLink(economists[0].person_id),
      );

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.primary_author.designation }}',
        economists[0].designation,
      );

      publicationHTML = replaceVariable(
        publicationHTML,
        '{{ publication.id }}',
        listItem._id,
      );

      if (economists.length <= 3) {
        _.map(economists, (economist) => {
          let economistName = economist.display_name;

          if (economist.position) {
            economistName += `, ${economist.position}`;
          }

          let authorHTML = replaceVariable(
            economistHTML,
            '{{ economist.display_name }}',
            economistName,
          );

          authorHTML = replaceVariable(
            authorHTML,
            '{{ economist.person_id }}',
            economist.person_id,
          );

          authorHTML = replaceVariable(
            authorHTML,
            '{{ economist.position }}',
            economist.position,
          );

          authorHTML = replaceVariable(
            authorHTML,
            '{{ economist.designation }}',
            economist.designation,
          );

          authorHTML = replaceVariable(
            authorHTML,
            '{{ economist.division_name }}',
            economist.division_name,
          );

          authorHTML = replaceVariable(
            authorHTML,
            '{{ economist.email }}',
            economist.email,
          );

          authorHTML = replaceVariable(
            authorHTML,
            '{{ economist.phone }}',
            economist.phone,
          );

          authorHTML = replaceVariable(
            authorHTML,
            '{{ economist.link }}',
            getEconomistLink(economist.person_id),
          );

          economistsHTML += authorHTML;
        });

        publicationHTML = replaceVariable(
          publicationHTML,
          '{{ economist.html_list }}',
          economistsHTML,
        );

        // Remove author-list html
        // publicationHTML = jQuery(publicationHTML).find('.author-list').remove();
      } else {
        // const list_html = jQuery(economistListHTML).find('ul').html();

        const authorHTMLTemplate = '<li class="author-link-list"><a href="{{ economist.link }}" aria-label="Navigates to the profile of {{ economist.display_name }}">{{ economist.display_name }} <span class="arrow-link-detail"></span></a></li>';

        _.map(economists, (economist) => {
          let authorHTML = replaceVariable(
            authorHTMLTemplate,
            '{{ economist.display_name }}',
            economist.display_name,
          );

          authorHTML = replaceVariable(
            authorHTML,
            '{{ economist.link }}',
            getEconomistLink(economist.person_id),
          );

          economistsHTML += authorHTML;
        });

        economistsHTML = `<div class="author-list"><ul>${economistsHTML}</ul></div>`;

        publicationHTML = replaceVariable(
          publicationHTML,
          '{{ economist.html_list }}',
          economistsHTML,
        );

        // Remove author name wrap from the html
        // publicationHTML = jQuery(publicationHTML).find('.author-name-wrap').remove();
      }

      publicationTemplateWrap += publicationHTML;
    });

    return publicationTemplateWrap;
  }

  sortFeature() {
    const temp = this;

    this.section.find('.table-sort-js').click(function () {
      const sort = jQuery(this).data('sort');
      let currentSortClass = jQuery(this).find('em').attr('class');
      const currentElement = temp.section.find(`.table-sort-js[data-sort="${sort}"]`);

      // if currentSortClass is undefined, then its clicked on tablet of mobile
      if (typeof currentSortClass === 'undefined') {
        currentSortClass = temp.section.find(`.mobile-filter-item[data-sort="${sort}"]`).find('em').attr('class');
      }

      // Remove active sorting class from all table headers
      temp.section.find('.table-header-titles .filter-btn').removeClass('sort-active');

      // Add active class to the current element clicked
      currentElement.find('.filter-btn').addClass('sort-active');

      // Set every sort column as descending by default
      temp.section.find('.table-sort-caret-js')
        .removeClass('up')
        .addClass('down');

      // Remove selected-filter from all dropdowns in mobile
      temp.section.find('.mobile-filter-item p')
        .removeClass('selected-filter');

      // Set selected-filter in mobile sort dropdown
      temp.section.find(`.mobile-filter-item[data-sort="${sort}"] p`)
        .addClass('selected-filter');

      let sortBy = 'desc';

      // Check if its already in descending state, if yes, then change it to ASC
      if (currentSortClass.indexOf('down') !== -1) {
        sortBy = 'asc';
        currentElement.find('em')
          .removeClass('down')
          .addClass('up');

        jQuery('.table-sort-mobile-js').find('em')
          .removeClass('down')
          .addClass('up');
      } else {
        currentElement.find('em')
          .removeClass('up')
          .addClass('down');

        jQuery('.table-sort-mobile-js').find('em')
          .removeClass('up')
          .addClass('down');
      }

      temp.setFilter('sort_by', sortBy);
      temp.setFilter('sort', sort);

      // Remove all publications from the list
      temp.resetPageNumber();
      temp.resetPublicationsList();

      // Set table sort for mobile
      jQuery('.table-sort-mobile-js').attr('data-sort', sort);

      // Change the text in mobile version. example: "Sort by 'Author'"
      const sortText = jQuery(this).find('span').text();
      jQuery('.filter-option-text').text(sortText);
      jQuery('.mobile-filter-btn').attr('aria-label', `sorts publications by ${sortText} in ascending order`);

      temp.renderPublicationsListing().then(function () {
        // If it's economist profile page, then disable link for author which is opened
        if (temp.section.data('publication') === 'economist_profile') {
          const economistID = jQuery('.economist-profile-discription').data('id');
          disableLinkForPrimaryAuthor(economistID);
        }
      });
    });

    this.section.find('.table-sort-mobile-js').click(function () {
      const sort = temp.section.find('.mobile-filter-item p.selected-filter')
        .parent()
        .data('sort');

      // Set arrows accordingly

      // Get the current arrow selected element
      const currentArrow = jQuery(this).find('em');
      const currentArrowClass = currentArrow.attr('class');

      // Set every sort column as descending by default
      temp.section.find('.table-sort-caret-js, .table-sort-mobile-js em.table-sort-caret-js')
        .removeClass('up')
        .addClass('down');

      filterSortBy = 'desc';

      // Check if its already in descending state, if yes, then change it to ASC
      if (currentArrowClass.indexOf('down') !== -1) {
        filterSortBy = 'asc';

        // Set the up arrow
        currentArrow.removeClass('down').addClass('up');
        jQuery('.table-sort-js .sort-active')
          .parent()
          .find('em.table-sort-caret-js')
          .removeClass('down')
          .addClass('up');
      }

      temp.setFilter('sort_by', filterSortBy);
      temp.setFilter('sort', sort);

      // Remove all publications from the list
      temp.resetPageNumber();
      temp.resetPublicationsList();

      // ======= Render Publications =======
      temp.renderPublicationsListing();

      // sort aria  label change
      const mobileSortAria = jQuery('.mobile-filter-btn').attr('aria-label');
      if (currentArrowClass.indexOf('down') !== -1) {
        jQuery('.mobile-filter-btn').attr('aria-label', mobileSortAria.replace('ascending', 'descending'));
      } else {
        jQuery('.mobile-filter-btn').attr('aria-label', mobileSortAria.replace('descending', 'ascending'));
      }
      jQuery('.mobile-filter-btn').attr('aria-live', 'polite');
    });
  }

  renderPublicationsListing(resetPublications) {
    return new Promise((resolve, reject) => {
      // Show loader
      loaderBegins();

      let API_URL = PUBLICATIONS_LIST_API;
      if (this.section.attr('data-publication') === 'econofacts') {
        API_URL = ECONOFACTS_PUBLICATIONS_API;
      }

      // Get the publication json data from the API
      const publications = getPublicationsJsonData(
        API_URL,
        this.filters,
      );
      const keywordSearchedDisplay = jQuery('#keywordSearched')[0];
      if (keywordSearchedDisplay) {
        keywordSearchedDisplay.textContent = this.filters.keyword;
      }
      const temp = this;

      publications.done(function (json) {
        if (typeof resetPublications !== 'undefined' && resetPublications === true) {
          temp.resetPageNumber();
          temp.resetPublicationsList();
        }

        temp.setPublications(json.hits.hits);

        if (window.PageNumber > 1) {
          jQuery('.publication-accordion-template').css({
            position: 'fixed',
          });
        }

        // Get total number of records
        const resultCount = json.hits.total.value;

        // Display value next to total in publication listing page
        jQuery('.main-publication-table .publication-result-count').html(`${resultCount} Results`);

        // Build the Publication HTML
        temp.publicationHtml = temp.buildPublicationsHTML(json.hits.hits);

        // Hide load more if there are no more publications to load
        if (json.hits.hits.length === 0 || json.hits.hits.length < parseInt(jQuery('.publication-result-card-block-wrap').data('limit'), 10)) {
          jQuery('.load-more-publications, .publication-sell-all-js').addClass('d-none');

          if (json.hits.hits.length === 0 && json.hits.total.value === 0) {
            showNoPublications();
          }
        } else {
          jQuery('.load-more-publications').removeClass('d-none');
        }

        let noOfPublications = getNoOfPublicationsRendered();

        setNoOfPublicationsRendered(noOfPublications + json.hits.hits.length);

        // Loader Ends
        loaderEnds();

        temp.renderPublicationsOnDOM();
        togglePublicationBookMarks();

        if (jQuery('.show-less-filter').find('span:first-child').length > 0) {
          if (jQuery('.show-less-filter').find('span:first-child').text() === 'Show Less Detail') {
            jQuery('.publication-result-card .publication-author img, .publication-result-card .publication-description').removeClass('d-none');
          } else {
            jQuery('.publication-result-card .publication-author img, .publication-result-card .publication-description').addClass('d-none');
          }
        }
        // custom tabbing for profile info window escape
        jQuery('.main-publication .preview-info-window, .main-publication-table .preview-info-window, .trending-articles-table .preview-info-window').on('keydown', function (event) {
          const closeIcon = jQuery(this).find('.close-icon');
          if ((event.keyCode === 27) || (event.which === 27)) {
            event.stopPropagation();
            jQuery(closeIcon).trigger('click');
            event.preventDefault();
            jQuery(closeIcon).parents('.publication-preview').find('.preview-icon').focus();
          }
          if ((event.keyCode === 9) || (event.which === 9)) {
            if (event.shiftKey) {
              event.stopPropagation();
              jQuery(closeIcon).trigger('click');
              event.preventDefault();
              jQuery(closeIcon).parents('.publication-preview').find('.preview-icon').focus();
            }
          }
        });
        // custom tabbing for close icon
        jQuery('.preview-info-window').find('.close-icon').on('keydown', function (e) {
          const closeIcon = this;
          if ((e.keyCode === 9) || (e.which === 9)) {
            jQuery(closeIcon).parent().focus();
            if (e.shiftKey) {
              e.stopPropagation();
              jQuery(closeIcon).focus();
            }
          }
        });
        jQuery('.preview-info-window').find('.preview-author-email').on('keydown', function (e) {
          if ((e.keyCode === 9) || (e.which === 9)) {
            if (e.shiftKey) {
              e.stopPropagation();
            }
          }
        });

        jQuery('.preview-info-window').find('.preview-author-phone').on('keydown', function (e) {
          if ((e.keyCode === 9) || (e.which === 9)) {
            if (e.shiftKey) {
              e.stopPropagation();
            }
          }
        });

        jQuery('.preview-info-window').find('.publication-title-preview').on('keydown', function (e) {
          if ((e.keyCode === 9) || (e.which === 9)) {
            if (e.shiftKey) {
              e.stopPropagation();
            }
          }
        });

        jQuery('.preview-info-window').find('.publication-right-section p a').on('keydown', function (e) {
          if ((e.keyCode === 9) || (e.which === 9)) {
            if (e.shiftKey) {
              e.stopPropagation();
            }
          }
        });

        // custom tabbing for profile image
        jQuery('.preview-info-window').find('.author-preview-image-name').on('keydown', function (e) {
          const previewImage = this;
          if ((e.keyCode === 9) || (e.which === 9)) {
            jQuery(previewImage).focus();
            if (e.shiftKey) {
              e.preventDefault();
              e.stopPropagation();
              jQuery(previewImage).parent().parent().parent()
                .find('.btn.close-icon')
                .focus();
            }
          }
          if ((e.keyCode === 13) || (e.which === 13)) {
            const href = jQuery(previewImage).attr('href');
            window.location.href = href;
            jQuery(previewImage).trigger('click');
          }
        });
        // custom tabbing for author image
        jQuery('.publication-result-card').find('.publication-author .author-image-name').on('keydown', function (event) {
          const authorImage = this;
          if ((event.keyCode === 9) || (event.which === 9)) {
            jQuery(authorImage).next().show();
            jQuery(authorImage).next().attr('tabindex', '0').focus();
            if (event.shiftKey) {
              jQuery(authorImage).next().hide();
              jQuery(authorImage).focus();
            }
          }
        });

        // custom hover for author image
        jQuery('.publication-result-card').find('.publication-author').hover(
          function () {
            jQuery(this).find('.author-image-name').next().show();
          },
          function () {
            jQuery(this).find('.author-image-name').next().hide();
          },
        );

        // custom tabbing for author info modal
        jQuery('.publication-result-card').find('.author-info-modal').on('keydown', function (e) {
          const infoModal = this;
          if ((e.keyCode === 27) || (e.which === 27)) {
            jQuery(infoModal).hide();
            jQuery(infoModal).prev().focus();
          }
          if (jQuery(this).find('.author-info-modal-template').length > 1) {
            jQuery(this).find('.arrow-link-detail').on('keydown', function (event) {
              if ((event.keyCode === 9) || (event.which === 9)) {
                jQuery(infoModal).show();
                jQuery(this).parent().parent()
                  .parent()
                  .parent()
                  .next()
                  .find('.author-name a')
                  .focus();
                if (event.shiftKey) {
                  jQuery(infoModal).show();
                  jQuery(this).focus();
                }
              }
            });
            jQuery(this).find('.author-info-modal-template:last-child .arrow-link-detail').on('keydown', function (event) {
              if ((event.keyCode === 9) || (event.which === 9)) {
                event.preventDefault();
                jQuery(infoModal).parent().next()
                  .next()
                  .find('a')
                  .attr('tabindex', '0')
                  .focus();
                jQuery(infoModal).hide();
                if (event.shiftKey) {
                  jQuery(infoModal).show();
                  jQuery(this).focus();
                }
              }
            });
            jQuery(this).find('.author-info-modal-template:first .author-name a').on('keydown', function (event) {
              if ((event.keyCode === 9) || (event.which === 9)) {
                if (event.shiftKey) {
                  jQuery(infoModal).hide();
                  jQuery(infoModal).focus();
                }
              }
            });
            jQuery(this).find('.author-info-modal-template:gt(1) .author-name a').on('keydown', function (event) {
              if ((event.keyCode === 9) || (event.which === 9)) {
                if (event.shiftKey) {
                  jQuery(infoModal).show();
                  jQuery(this).focus();
                }
              }
            });
          } else {
            if (jQuery(this).find('.arrow-link-detail').css('display') === 'none') {
              jQuery(this).find('.individual-author-contact a:last-child').on('keydown', function (event) {
                if ((event.keyCode === 9) || (event.which === 9)) {
                  event.preventDefault();
                  jQuery(infoModal).parent().next()
                    .next()
                    .find('a')
                    .attr('tabindex', '0')
                    .focus();
                  jQuery(infoModal).hide();
                  if (event.shiftKey) {
                    jQuery(infoModal).show();
                    jQuery(this).focus();
                  }
                }
              });
            } else {
              jQuery(this).find('.arrow-link-detail').on('keydown', function (event) {
                if ((event.keyCode === 9) || (event.which === 9)) {
                  event.preventDefault();
                  jQuery(infoModal).parent().next()
                    .next()
                    .find('a')
                    .attr('tabindex', '0')
                    .focus();
                  jQuery(infoModal).hide();
                  if (event.shiftKey) {
                    jQuery(infoModal).show();
                    jQuery(this).focus();
                  }
                }
              });
            }
            jQuery(this).find('.author-name a, .author-link-list:first-child').on('keydown', function (event) {
              if ((event.keyCode === 9) || (event.which === 9)) {
                if (event.shiftKey) {
                  jQuery(infoModal).focus();
                  jQuery(infoModal).hide();
                }
              }
            });
          }
          jQuery(this).find('.author-link-list:last-child').on('keydown', function (event) {
            if ((event.keyCode === 9) || (event.which === 9)) {
              event.preventDefault();
              jQuery(infoModal).hide();
              jQuery(infoModal).parent().next()
                .next()
                .find('a')
                .attr('tabindex', '0')
                .focus();
              if (event.shiftKey) {
                jQuery(infoModal).show();
                jQuery(this).focus();
              }
            }
          });
        });
        noOfPublications = getNoOfPublicationsRendered();
        if (json.hits.total.value === noOfPublications) {
          jQuery('.load-more-publications, .publication-sell-all-js').addClass('d-none');
        }

        // Used in recent releases and economist profile
        if (temp.showLoadMoreAlways === true) {
          jQuery('.publication-sell-all-js').removeClass('d-none');
        }
        resolve(json);
        resetStickyPositionTop();
        scrollTopIfNoData();
      }).fail(function () {
        reject();
      });
    });
  }

  pagination() {
    const temp = this;
    let count = parseInt(jQuery('.publication-result-card-block-wrap.main-publication').attr('data-limit'), 10) + 1;

    this.section.find('.load-more-publications').click(function (e) {
      e.preventDefault();
      const loadMorebutton = jQuery(this);
      const oldtext = loadMorebutton.text();
      loadMorebutton.text('Loading...');

      temp.filters.page += 1;

      temp.renderPublicationsListing(temp.filters).then(function () {
        loadMorebutton.text(oldtext);
        if (jQuery('#sidebar').length > 0) {
          resetStickyPositionOnLoadMore();
          const focusEle = jQuery(`.publication-result-card:nth-child(${count}) .publication-author .author-image-name`);
          $('html,body').animate({
            scrollTop: focusEle.offset().top - 100,
          }, 1500);
          focusEle.focus();
          const incCount = parseInt(jQuery('.publication-result-card-block-wrap.main-publication').attr('data-limit'), 10);
          count += incCount;
        }
      });
    });
  }
}
