import {
  BEDateFormat,
  parseURLDateToDate,
} from './utils';

const MONTH_FORMAT = 'MM/YYYY';

export default class CalenderWidget {
  constructor() {
    this.calendarWidget = '';
    this.fromDate = '';
    this.toDate = '';
    this.currentDate = '';
    this.diabledEconDates = [];
    this.diabledCBDDates = [];
    this.diabledEconMonths = [];
    this.diabledCBDMonths = [];
    this.econDateList = [];
    this.cbdDateList = [];
    this.calendarType = '';
    this.parentThis = '';
  }

  setFromAndToDate(fromDate, toDate) {
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

  setParentThis(parentThis) {
    this.parentThis = parentThis;
  }

  setCurrentSelectDate(date) {
    this.calendarWidget.datepicker('update', date);
    this.parentThis.dateChangeOnCalendarCallBack(date);
    this.currentDate = date;
  }

  buildMonthDropDown() {
    const monthList = [];
    const startDate = moment(this.fromDate, BEDateFormat);
    const endDate = moment(this.toDate, BEDateFormat);
    const totalNumberOfMonths = endDate.diff(startDate, 'months') + 1;
    _.map(_.range(totalNumberOfMonths), (num) => {
      const month = moment(startDate, BEDateFormat).add(num, 'months');
      monthList.push(`<li class="month-select" onKeydown="onkeySelect(event)" id="month-${month.format(MONTH_FORMAT)}" aria-label="${month.format('MMMM YYYY')}">${month.format('MMMM YYYY')}</li>`);
    });
    const monthContainer = jQuery('.calendar-month-dropdown');
    _.map(monthList, (li) => {
      monthContainer.append(li);
    });
  }

  intitializeWidget() {
    const calendarWidget = jQuery('.filter-card #datepicker.calendar-widget');
    calendarWidget.datepicker({
      todayHighlight: true,
      autoclose: false,
      format: 'mm/dd/yyyy',
      maxViewMode: 0,
    });
    calendarWidget.datepicker('setDate', this.fromDate).datepicker('setEndDate', this.toDate).datepicker('setStartDate', this.fromDate).datepicker('hide', function () {});
    calendarWidget.datepicker('show');
    this.buildMonthDropDown();
    this.calendarWidget = calendarWidget;
    jQuery('#datepicker .datepicker-days thead').find('tr:nth-child(2) th:nth-child(2)').attr('tabindex', '0');
    $('.datepicker .datepicker-prv-nxt .button-container a').attr('aria-hidden', true);
    $('.datepicker .datepicker-days .datepicker-prv-nxt .button-container a').attr('aria-hidden', false);
  }

  setDisableEnableDates() {
    // Creates array of disabled and enabled dates for individual calendar
    const allDates = [];
    const startDate = moment(this.fromDate, BEDateFormat);
    const endDate = moment(this.toDate, BEDateFormat);
    const totalNumberOfDays = endDate.diff(startDate, 'days') + 1;
    _.map(_.range(totalNumberOfDays), (num) => {
      allDates.push(moment(startDate, BEDateFormat).add(num, 'days').format(BEDateFormat));
    });
    const getAllEconEventIdList = [];
    const getAllCBDEventIdList = [];
    _.map(jQuery('.econ-calendar-list .calendar-date-wrap'), (ele) => {
      let dateId = ele.id.split('econ-')[1];
      dateId = parseURLDateToDate(dateId);
      getAllEconEventIdList.push(dateId);
    });
    _.map(jQuery('.cbd-calendar-list .calendar-date-wrap'), (ele) => {
      let dateId = ele.id.split('cbd-')[1];
      dateId = parseURLDateToDate(dateId);
      getAllCBDEventIdList.push(dateId);
    });
    const diabledEconDates = [];
    const diabledEconMonths = [];
    const diabledCBDDates = [];
    const diabledCBDMonths = [];

    let currentLoopingMonth = moment(this.fromDate, BEDateFormat).format(MONTH_FORMAT);
    let nonDisabledDatesOfTheMonthEcon = 0;
    let nonDisabledDatesOfTheMonthCBD = 0;

    _.map(allDates, (date) => {
      const newMonth = moment(date, BEDateFormat).format(MONTH_FORMAT);
      if (currentLoopingMonth !== newMonth) {
        if (nonDisabledDatesOfTheMonthEcon === 0) {
          diabledEconMonths.push(currentLoopingMonth);
        }
        nonDisabledDatesOfTheMonthEcon = 0;
      }
      if (currentLoopingMonth !== newMonth) {
        if (nonDisabledDatesOfTheMonthCBD === 0) {
          diabledCBDMonths.push(currentLoopingMonth);
        }
        nonDisabledDatesOfTheMonthCBD = 0;
      }
      if (!_.includes(getAllCBDEventIdList, date)) {
        diabledCBDDates.push(date);
      } else {
        nonDisabledDatesOfTheMonthCBD += 1;
      }
      if (!_.includes(getAllEconEventIdList, date)) {
        diabledEconDates.push(date);
      } else {
        nonDisabledDatesOfTheMonthEcon += 1;
      }
      currentLoopingMonth = newMonth;
    });
    if (nonDisabledDatesOfTheMonthCBD === 0) {
      diabledCBDMonths.push(currentLoopingMonth);
    }
    if (nonDisabledDatesOfTheMonthEcon === 0) {
      diabledEconMonths.push(currentLoopingMonth);
    }
    this.diabledEconDates = diabledEconDates;
    this.diabledCBDDates = diabledCBDDates;
    this.diabledEconMonths = diabledEconMonths;
    this.diabledCBDMonths = diabledCBDMonths;
    this.econDateList = getAllEconEventIdList;
    this.cbdDateList = getAllCBDEventIdList;
  }

  disableMonthsLi() {
    const monthLi = jQuery('.calendar-month-dropdown li');
    _.map(monthLi, (li) => {
      const element = jQuery(li);
      const month = element.attr('id').split('month-')[1];
      const econDisbaled = _.includes(this.diabledEconMonths, month);
      const CBDDisbaled = _.includes(this.diabledCBDMonths, month);
      element.addClass(`${econDisbaled ? 'econ-disabled' : ''} ${CBDDisbaled ? 'cbd-disabled' : ''}`);
      if (econDisbaled) {
        element.attr('aria-disabled', true);
      } else {
        element.attr('aria-disabled', false);
      }
    });
  }

  highlightEconDates() {
    const disbaledArray = this.diabledEconDates || [];
    jQuery('.calendar-month-dropdown').removeClass('cbd-month').addClass('econ-month');
    this.calendarWidget.datepicker('setDatesDisabled', disbaledArray);
    // if (this.econDateList) {
    //   this.setCurrentSelectDate(this.econDateList[0]);
    // }
    this.calendarType = 'ECON';
  }

  highlightCBDDates() {
    const disbaledArray = this.diabledCBDDates || [];
    jQuery('.calendar-month-dropdown').removeClass('econ-month').addClass('cbd-month');
    this.calendarWidget.datepicker('setDatesDisabled', disbaledArray);
    // if (this.cbdDateList) {
    //   this.setCurrentSelectDate(this.cbdDateList[0]);
    // }
    this.calendarType = 'CBD';
  }

  setOnClickFunctions() {
    const tableHead = jQuery('#datepicker .datepicker-days thead');
    const tableHeadNew = jQuery('#datepicker .datepicker-days .datepicker-prv-nxt');
    const monthDoropDown = jQuery('.calendar-month-dropdown');
    tableHead.find('tr:nth-child(2) th:nth-child(2)').attr('colspan', 12);
    tableHead.on('click', 'tr:nth-child(2)', () => {
      tableHead.find('tr:nth-child(2) th:nth-child(2)').toggleClass('chevron-up');
      monthDoropDown.toggleClass('show');
      jQuery('.calendar-month-dropdown li:first').focus();
    });

    tableHeadNew.on('click', '.datepicker-switch', () => {
      tableHeadNew.find('.datepicker-switch').toggleClass('chevron-up');
      monthDoropDown.toggleClass('show');
      jQuery('.calendar-month-dropdown li:first').focus();
    });
    const calEle = this;
    let hide = true;
    jQuery('body').on('click', function (e) {
      let notOnTooltip = true;
      const targetEle = jQuery(e.target);
      // prevent the close click on the dropdown menu.
      if (targetEle.hasClass('month-select') || targetEle.parents('.month-select').length) {
        notOnTooltip = false;
      }
      if (hide && notOnTooltip) {
        monthDoropDown.removeClass('show');
        tableHead.find('tr:nth-child(2) th:nth-child(2)').removeClass('chevron-up');
        tableHeadNew.find('.datepicker-switch').removeClass('chevron-up');
        if ($(e.target).hasClass('chevron-up')) {
          $('.datepicker-days td').attr('tabindex', '-1');
        } else {
          $('.datepicker-days td').attr('tabindex', '0');
        }
      }
      hide = true;
    });
    // month selection
    jQuery('.calendar-widget-wrap').on('click', '.calendar-month-dropdown.econ-month li:not(.econ-disabled), .calendar-month-dropdown.cbd-month li:not(.cbd-disabled)', (e) => {
      const monthDate = moment(e.target.id.split('month-')[1], MONTH_FORMAT);
      let arrayToCheck = [];
      if (calEle.calendarType === 'ECON') {
        arrayToCheck = calEle.econDateList;
      } else {
        arrayToCheck = calEle.cbdDateList;
      }
      const activeDateInTheMonth = _.find(arrayToCheck, (date) => {
        const bool = moment(date, BEDateFormat).isSame(monthDate, 'month');
        return bool;
      });
      let date = '';
      if (activeDateInTheMonth) {
        date = activeDateInTheMonth;
      } else {
        date = monthDate.format(BEDateFormat);
      }
      calEle.setCurrentSelectDate(date);
      monthDoropDown.removeClass('show');
      tableHead.find('tr:nth-child(2) th:nth-child(2)').removeClass('chevron-up');
      tableHeadNew.find('.datepicker-switch').removeClass('chevron-up');
      if (!$('.datepicker-days table tbody tr:last-child td').is(':visible')) {
        $('.datepicker-days table tbody tr:last-child').attr({ 'aria-hidden': true, role: 'none' });
      }
    });
  }

  disableEnableDates() {
    this.setDisableEnableDates();
    this.disableMonthsLi();
    this.setOnClickFunctions();
  }
}
