import {
  downloadICSFile,
} from './apis';

import {
  cloneTemplate,
  PUBLICATION_LISTING_PAGE,
  getPublicationLink,
  BEDateFormat,
  CALENDAR_SLUG,
  CALENDAR_FLAGS_URL,
} from './utils';

function getRecentSearchFromStorage() {
  const recentSearchList = localStorage.getItem('recentSearchList');
  if (recentSearchList) {
    return JSON.parse(recentSearchList);
  }
  return [];
}

function setRecentSearchToStorage(list) {
  if (_.isArray(list)) {
    localStorage.setItem('recentSearchList', JSON.stringify(list));
  }
}

function hideShowMoreButtonIfNoResults(targetEleName, len) {
  const showLessToggleEle = jQuery(targetEleName);
  if (len <= 1) {
    showLessToggleEle.removeClass('d-block').addClass('d-none');
  } else {
    showLessToggleEle.addClass('d-block').removeClass('d-none');
  }
}

function loadPublicationResults(data) {
  let cookieData = getRecentSearchFromStorage();
  const parentEle = jQuery('.publication-results-container');
  parentEle.siblings('.see-all-anchor').attr('href', `/${PUBLICATION_LISTING_PAGE}/`);
  parentEle.empty();
  const searchKeyword = jQuery('#search-input-text-box').val();
  if (!_.isEmpty(data)) {
    _.map(data, (obj) => {
      const clonedEle = cloneTemplate('publication-result-wrap-template');
      clonedEle.find('.date').text(moment(obj._source.display_datetime, 'YYYY-MM-DDTh:mm:ss').format(BEDateFormat));
      const title = `${obj._source.title} (${obj._source.industry.name})`;
      clonedEle.find('.text').text(title);
      const link = `${getPublicationLink(obj._id, obj._source.product_id, obj._source.historical_publication)}?keyword=${searchKeyword}`;
      const target = obj._source.historical_publication ? '_blank' : '_self';
      const historicalPublication = obj._source.historical_publication;
      clonedEle.find('.anchor-wrap').attr({ href: link, target });
      clonedEle.find('.anchor-wrap').attr('aria-label', `Navigates to the ${title} details page`);
      clonedEle.on('click', '.anchor-wrap', function storeInCookie() {
        if (!_.find(cookieData, { link, title, historicalPublication })) {
          cookieData.unshift({ link, title, historicalPublication });
          cookieData = _.slice(cookieData, 0, 3);
          setRecentSearchToStorage(cookieData);
        }
      });
      parentEle.append(clonedEle);
    });
  } else {
    const clonedEle = cloneTemplate('no-match-found-template');
    parentEle.append(clonedEle);
  }
  hideShowMoreButtonIfNoResults('.publication-search-results .auto-suggest.show-less-more-toggle', data.length);
}

function loadCalendarResults(data) {
  const parentEle = jQuery('.calendar-results-container');
  const fallbackFlagName = parentEle.attr('data-internationalflag');
  parentEle.siblings('.see-all-anchor').attr('href', `/${CALENDAR_SLUG}/`);
  parentEle.empty();
  if (!_.isEmpty(data)) {
    _.map(data, (obj) => {
      const clonedEle = cloneTemplate('calendar-result-wrap-template');

      if (fallbackFlagName.indexOf(obj._source.alpha3_country_code) !== -1) {
        clonedEle.addClass('international');
      }

      const formattedDatetime = moment.utc(
        obj._source.event_start_time,
        'YYYY-MM-DDTh:mm:ss',
      ).local().format(
        `${BEDateFormat} - h:mma`,
      );

      clonedEle.find('.date').text(formattedDatetime);

      clonedEle.find('.text').html(obj._source.event_name_plain_text);

      const flagEle = clonedEle.find('.flag');
      flagEle.attr('src', `${CALENDAR_FLAGS_URL}${obj._source.alpha3_country_code}.svg`);
      flagEle.attr('onerror', `this.src='${fallbackFlagName}'; jQuery(this).parent().addClass('international');`);
      flagEle.attr('title', obj._source.alpha3_country_code);
      clonedEle.on('click', function onClickForICSDownload() {
        downloadICSFile(parseInt(obj._id, 10));
      });
      parentEle.append(clonedEle);
    });
  } else {
    const clonedEle = cloneTemplate('no-match-found-template');
    parentEle.append(clonedEle);
  }
  hideShowMoreButtonIfNoResults('.calendar-search-results .auto-suggest.show-less-more-toggle', data.length);
}

function loadRecentSearches() {
  const parentEle = jQuery('.recent-search-results-container');
  const data = getRecentSearchFromStorage();
  parentEle.empty();
  if (!_.isEmpty(data)) {
    _.map(data, (obj) => {
      const clonedEle = cloneTemplate('link-wrap-template');
      clonedEle.find('.text').text(obj.title);
      clonedEle.find('.anchor-wrap').attr('href', obj.link);
      clonedEle.find('.anchor-wrap').attr('aria-label', `Navigates to the ${obj.title} details page`);
      if (obj.historicalPublication === true) {
        clonedEle.find('.anchor-wrap').attr('target', '_blank');
      }
      parentEle.append(clonedEle);
    });
  } else {
    const clonedEle = cloneTemplate('no-recent-search-template');
    parentEle.append(clonedEle);
  }
}

export function showMoreLessAutoSuggestAccordian() {
  jQuery('#autoSuggestSearchContainer').on('click', '.auto-suggest.show-less-more-toggle', function showLessAuto() {
    const currentEle = jQuery(this);
    const sibblingEle = currentEle.parent('.result-header').siblings('.results-container');
    if (currentEle.hasClass('show-less')) {
      currentEle.removeClass('show-less');
      sibblingEle.addClass('show-less');
      currentEle.find('.toggle-text').text('Show More');
      currentEle.find('.drop-down-icon').addClass('down');
    } else {
      currentEle.addClass('show-less');
      sibblingEle.removeClass('show-less');
      currentEle.find('.toggle-text').text('Show Less');
      currentEle.find('.drop-down-icon').removeClass('down');
    }
  });
}

export function buildStructure(results) {
  loadPublicationResults((results.length > 0 && results[0].hits && results[0].hits.hits) || {});
  loadCalendarResults((results.length > 1 && results[1].hits && results[1].hits.hits) || {});
  loadRecentSearches();
}

export function showAutoSearch(bool) {
  if (bool) {
    const autoSuggestEle = jQuery('#autoSuggestSearchContainer');
    autoSuggestEle.removeClass('d-none');
    window.scrollTo(0, 0);
    jQuery('body').addClass('modal-open');
    jQuery('.sticky-nav-wrap').addClass('auto-suggest');
  } else {
    jQuery('#autoSuggestSearchContainer').addClass('d-none');
    jQuery('.sticky-nav-wrap').removeClass('auto-suggest');
    jQuery('body').removeClass('modal-open');
  }
}
