import {
  BEDateFormat,
  defaultFilters,
  DEFAULT_NUMBER_OF_MONTHS,
  isDesktop,
  isMobile,
  isTab,
  horizontalResize,
  getQueryString,
  changePageUrl,
  parseQueryString,
  parseDateToURLDate,
  parseURLDateToDate,
  selectCurrentActiveDay,
  calenderActiveRows,
  calendarMonthExpandState,
} from './utils';

import {
  stickyFilterSection,
  resetStickyPositionCalendarTop,
} from './publication_utils';

import {
  getEventsDataAPI,
  downloadICSFile,
} from './apis';

import CalenderListView from './calendar_list';
import CalenderWidget from './calendar_widget';

let currentCalType = defaultFilters.calenderType;
let onClickScroll = false;
let currentDate = '';
let currentCountryType = defaultFilters.countryType;
let initialLoad = true;

function toggleEventLoader(bool) {
  const loader = jQuery('.calendar-loader');
  if (bool) {
    loader.addClass('d-none');
  } else {
    loader.removeClass('d-none');
  }
}

function showHidePrint(currentType) {
  const cbdLink = jQuery('#cbdPrintLink');
  const econLink = jQuery('#econPrintLink');
  const appendixLink = jQuery('#cbdAppendixLink');
  if (currentType === 'ECON') {
    cbdLink.addClass('d-none');
    appendixLink.addClass('d-none');
    econLink.removeClass('d-none');
  } else if (currentType === 'CBD') {
    econLink.addClass('d-none');
    cbdLink.removeClass('d-none');
    appendixLink.removeClass('d-none');
  }
}

function setQueryParams() {
  let countryString = '';
  _.map(currentCountryType, (country) => {
    countryString = `${countryString}${countryString ? ',' : ''}${country}`;
  });
  const queryString = getQueryString({
    calendarType: currentCalType,
    date: currentDate,
    countryType: countryString,
  });
  changePageUrl(queryString);
}

function dateChange(date) {
  currentDate = parseDateToURLDate(date);
  setQueryParams();
}

function stickyFilter() {
  if (((jQuery(window).scrollTop() > 62 && isTab())
    || (jQuery(window).scrollTop() > 50 && isMobile()))
    && jQuery('#autoSuggestSearchContainer').hasClass('d-none')) {
    jQuery('.calender-header').addClass('sticky-action');
    jQuery('.empty-calender-header').removeClass('d-none');
  } else {
    jQuery('.calender-header').removeClass('sticky-action');
    jQuery('.empty-calender-header').addClass('d-none');
  }
}

function onDateChange(date, calenderType) {
  const dateId = parseDateToURLDate(date);
  let viewPointOffset = 140;
  if (isTab()) {
    viewPointOffset = 195;
    if (initialLoad) {
      viewPointOffset += 50;
    }
  } else if (isMobile()) {
    viewPointOffset = 115;
  }
  if (calenderType === 'ECON') {
    const ele = jQuery(`#econ-${dateId}`);
    if (ele.length) {
      onClickScroll = true;
      jQuery('html, body').animate({
        scrollTop: ele.offset().top - viewPointOffset,
      }, 1000, () => {
        onClickScroll = false;
      });
    }
  } else {
    const ele = jQuery(`#cbd-${dateId}`);
    if (ele.length) {
      onClickScroll = true;
      jQuery('html, body').animate({
        scrollTop: ele.offset().top - viewPointOffset,
      }, 1000, () => {
        onClickScroll = false;
      });
    }
  }
}

function getDefaultFilterParams() {
  return _.cloneDeep(defaultFilters);
}

function windowResize() {
  const filterCard = jQuery('#filterCard');
  if (isDesktop()) {
    filterCard.appendTo('.calendar-filter-column .sidebar__inner');
    jQuery('#filterModal').modal('hide');
    jQuery('.calender-header').removeClass('sticky-action');
    jQuery('.empty-calender-header').addClass('d-none');
  } else if (isMobile() || isTab()) {
    filterCard.appendTo('#calendar-filter-modal');
    jQuery('.filter-card').removeClass('sticky-action').css('width', '');
  }
  const filterParentWidth = jQuery('.calendar-filter-column').width();
  jQuery('#sidebar .sidebar__inner').css('width', `${filterParentWidth}px`);
  horizontalResize(() => {
    const filterColumnWidth = jQuery('.calendar-filter-column').width();
    if (filterCard.hasClass('sticky-action')) {
      filterCard.css('width', `${filterColumnWidth}px`);
    }
  });
  resetStickyPositionCalendarTop();
}

function scrollHighlight() {
  const calendarWidget = jQuery('.filter-card #datepicker.calendar-widget');
  const econElementArray = [];
  const cbdElementArray = [];
  let viewPointOffset = 205;
  if (isTab()) {
    viewPointOffset = 200;
  } else if (isMobile()) {
    viewPointOffset = 118;
  }
  _.map(jQuery('.calendar-date-wrap'), (ele) => {
    const element = jQuery(ele);
    if ((element.offset().top - viewPointOffset) < jQuery(window).scrollTop()) {
      if (element.hasClass('econ-event')) {
        econElementArray.push(element);
      } else if (element.hasClass('cbd-event')) {
        cbdElementArray.push(element);
      }
    }
  });
  const econElement = _.last(econElementArray);
  if (econElement) {
    const id = econElement.attr('id');
    let date = '';
    if (currentCalType === 'ECON' && !onClickScroll) {
      date = id.split('econ-');
      const dateFormat = date[1];
      if (dateFormat !== currentDate) {
        calendarWidget.datepicker('update', dateFormat);
        dateChange(dateFormat);
      }
    }
  }
  const cbdElement = _.last(cbdElementArray);
  if (cbdElement) {
    const id = cbdElement.attr('id');
    let date = '';
    if (currentCalType === 'CBD' && !onClickScroll) {
      date = id.split('cbd-');
      const dateFormat = date[1];
      if (dateFormat !== currentDate) {
        calendarWidget.datepicker('update', dateFormat);
        dateChange(dateFormat);
      }
    }
  }
}

function windowScroll() {
  stickyFilter();
  scrollHighlight();
  stickyFilterSection();
}

export default class CalenderControlView {
  constructor() {
    this.calenderListView = new CalenderListView();
    this.calenderWidget = new CalenderWidget();
    this.filtersParms = getDefaultFilterParams();
    this.eventsData = '';
  }

  async getCalendarHTMLStructure() {
    let totalMonths = jQuery('#calenderContainer').attr('data-months');
    const fromDate = this.filtersParms.startDate;
    let toDate = this.filtersParms.endDate;
    if (totalMonths) {
      totalMonths = parseInt(totalMonths, 10);
    }
    if (totalMonths !== DEFAULT_NUMBER_OF_MONTHS) {
      toDate = moment().add(totalMonths, 'M').endOf('month').format(BEDateFormat);
      this.filtersParms.endDate = toDate;
    }
    let htmlData = '';
    try {
      // Get data from API or if exist from the window varaiable (end_date)
      htmlData = await getEventsDataAPI(fromDate, toDate);
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
    return htmlData;
  }

  applyFilterParams() {
    const params = this.filtersParms;
    const econRadio = jQuery('#calendarEventsEconomics input');
    const cbdRadio = jQuery('#calendarEventsCentralBank input');
    const cbdEveList = jQuery('.cbd-calendar-list');
    const econEveList = jQuery('.econ-calendar-list');
    const parsedDate = parseURLDateToDate(currentDate);
    let dateSet = '';
    if (params.calenderType === 'ECON') {
      econRadio.prop('checked', true);
      econRadio.attr('aria-checked', 'true');
      cbdRadio.prop('checked', false);
      cbdRadio.attr('aria-checked', 'false');
      cbdEveList.addClass('d-none');
      econEveList.removeClass('d-none');
      this.calenderWidget.highlightEconDates();
      if (!_.isEmpty(this.calenderWidget.econDateList)) {
        if (_.includes(this.calenderWidget.econDateList, parsedDate)) {
          dateSet = parsedDate;
        } else {
          const currentMoment = moment(parsedDate, BEDateFormat);
          const setDate = _.find(this.calenderWidget.econDateList, function (date) {
            return moment(date, BEDateFormat).isSameOrAfter(currentMoment);
          });
          if (setDate) {
            dateSet = setDate;
          } else {
            dateSet = params.startDate;
          }
        }
      } else {
        dateSet = params.startDate;
      }
    } else {
      cbdRadio.prop('checked', true);
      cbdRadio.attr('aria-checked', 'true');
      econRadio.prop('checked', false);
      econRadio.attr('aria-checked', 'false');
      econEveList.addClass('d-none');
      cbdEveList.removeClass('d-none');
      this.calenderWidget.highlightCBDDates();
      if (!_.isEmpty(this.calenderWidget.cbdDateList)) {
        if (_.includes(this.calenderWidget.cbdDateList, parsedDate)) {
          dateSet = parsedDate;
        } else {
          const currentMoment = moment(parsedDate, BEDateFormat);
          const setDate = _.find(this.calenderWidget.cbdDateList, function (date) {
            return moment(date, BEDateFormat).isSameOrAfter(currentMoment);
          });
          if (setDate) {
            dateSet = setDate;
          } else {
            dateSet = params.startDate;
          }
        }
      } else {
        dateSet = params.startDate;
      }
    }
    // This is to filter Econ based on country
    _.map(jQuery('.country-list'), (ele) => {
      const element = jQuery(ele);
      const country = element.attr('data-area-name');
      const className = `.econ-calendar-list .media.${_.lowerCase(country)}`;
      if (_.includes(params.countryType, country)) {
        jQuery(element).prop('checked', true);
        jQuery(className).removeClass('d-none');
      } else {
        jQuery(element).prop('checked', false);
        jQuery(className).addClass('d-none');
      }
    });
    // This is to show no events if filters are applied logic
    _.map(jQuery('.econ-calendar-list .calendar-date-wrap'), (ele) => {
      const element = jQuery(ele);
      if (element.children('.media:not(.d-none)').length === 0) {
        element.find('.no-data-to-show').removeClass('d-none');
      } else {
        element.find('.no-data-to-show').addClass('d-none');
      }
    });
    // Here the update and date set is to perform after reder to scroll is on point.
    this.calenderWidget.calendarWidget.datepicker('update', parseURLDateToDate(dateSet));
    this.dateChangeOnCalendarCallBack(dateSet);
  }

  setFilterParams(filtersParms) {
    this.filtersParms = filtersParms;
    currentCalType = filtersParms.calenderType;
    currentCountryType = filtersParms.countryType;
    showHidePrint(currentCalType);
    setQueryParams();
    this.applyFilterParams();
    this.addBottomPadding();
    resetStickyPositionCalendarTop();
  }

  onClickFunctions() {
    const filtersParms = _.cloneDeep(this.filtersParms);
    const calThis = this;
    jQuery('#calendarEventsCentralBank').on('click', function () {
      // on click function CBD radio
      filtersParms.calenderType = 'CBD';
      filtersParms.countryType = [];
      calThis.setFilterParams(filtersParms);
    });
    jQuery('#calendarEventsEconomics').on('click', function () {
      // on click function Economics radio
      filtersParms.calenderType = 'ECON';
      filtersParms.countryType = getDefaultFilterParams().countryType;
      calThis.setFilterParams(filtersParms);
    });
    jQuery('.country-list').on('click', function () {
      // on click function checkbox
      const checkedBox = jQuery(this).attr('data-area-name');
      if (_.includes(filtersParms.countryType, checkedBox)) {
        if (filtersParms.countryType.length !== 1) {
          _.remove(filtersParms.countryType, (country) => {
            if (country === checkedBox) {
              return true;
            }
            return false;
          });
        }
      } else {
        filtersParms.countryType.push(checkedBox);
      }
      if (filtersParms.calenderType === 'CBD') {
        filtersParms.calenderType = 'ECON';
      }
      calThis.setFilterParams(filtersParms);
    });
    jQuery('.calender-view-main').on('click', '.media.events-data .download-ics, .media.events-data .media-body, .cbd-data-wrap .media-body.text, .cbd-data-wrap .download-ics', function onClickForICSDownload() {
      const clickedEle = jQuery(this);
      let id = '';
      if (clickedEle.hasClass('media-body')) {
        if (isDesktop()) {
          id = clickedEle.siblings('img.download-ics').attr('data-eventid');
        }
      } else if (clickedEle.hasClass('download-ics')) {
        id = clickedEle.attr('data-eventid');
      }
      if (id) {
        downloadICSFile(parseInt(id, 10));
      }
    });
  }

  setEventsData(htmlData) {
    this.eventsData = htmlData;
  }

  setEventsInListView() {
    this.calenderListView.setEvents(this.eventsData);
  }

  renderEventsData() {
    this.setEventsInListView();
    this.calenderListView.renderEventsList();
  }

  setFromToDateInWidget() {
    this.calenderWidget.setFromAndToDate(this.filtersParms.startDate, this.filtersParms.endDate);
    dateChange(this.filtersParms.startDate);
  }

  onDateSelect() {
    this.calenderWidget.calendarWidget.on('changeDate', (date) => {
      this.calenderWidget.currentDate = date.format();
      dateChange(date.format());
      if (isDesktop()) {
        onDateChange(date.format(), this.filtersParms.calenderType);
      }
      selectCurrentActiveDay();
    });
  }

  dateChangeOnCalendarCallBack(date) {
    const filtersParm = this.filtersParms;
    let firstDate = '';
    let firstDateList = this.calenderWidget.econDateList;
    if (filtersParm.calenderType === 'CBD') {
      firstDateList = this.calenderWidget.cbdDateList;
    }
    if (!_.isEmpty(firstDateList)) {
      firstDate = parseDateToURLDate(firstDateList[0]);
    }

    dateChange(date);
    // If default filters the scroll top
    if (currentDate === firstDate) {
      // Scroll top
      window.scrollTo(0, 0);
    } else {
      onDateChange(date, filtersParm.calenderType);
    }
  }

  renderFilterData() {
    this.setFromToDateInWidget();
    this.calenderWidget.setParentThis(this);
    this.calenderWidget.intitializeWidget();
    this.calenderWidget.disableEnableDates();
    this.onDateSelect();
  }

  scrollAfterModalScroll() {
    jQuery('#filterModal').on('hidden.bs.modal', () => {
      jQuery('body').removeClass('body-scroll');
      onDateChange(currentDate, this.filtersParms.calenderType);
    });
    jQuery('#filterModal').on('show.bs.modal', () => {
      jQuery('body').addClass('body-scroll');
    });
  }

  setFilterByQueryParam(queryString) {
    const filterObject = parseQueryString(queryString);
    const filtersParms = _.cloneDeep(this.filtersParms);
    let isValid = true;
    if (_.has(filterObject, 'calendarType')) {
      if (filterObject.calendarType === 'ECON' || filterObject.calendarType === 'CBD') {
        filtersParms.calenderType = filterObject.calendarType;
        if (filterObject.calendarType === 'CBD') {
          filtersParms.countryType = [];
        }
      } else {
        isValid = false;
      }
    }
    if (_.has(filterObject, 'date')) {
      if (moment(filterObject.date, 'MM-DD-YYYY').isValid()) {
        const date = parseURLDateToDate(filterObject.date);
        this.dateChangeOnCalendarCallBack(date);
      } else {
        isValid = false;
      }
    }
    if (_.has(filterObject, 'countryType')) {
      if (filterObject.countryType) {
        const array = _.split(filterObject.countryType, ',') || [];
        if (!_.isEmpty(array)) {
          const validArray = _.filter(array, function (country) {
            return _.includes(defaultFilters.countryType, country);
          });
          if (!_.isEmpty(validArray) && filtersParms.calenderType === 'ECON') {
            filtersParms.countryType = validArray;
          } else {
            isValid = false;
          }
        }
      } else if (filtersParms.calenderType === 'ECON') {
        filtersParms.countryType = defaultFilters.countryType;
      } else {
        filtersParms.countryType = [];
      }
    }
    if (isValid) {
      this.filtersParms = filtersParms;
      currentCalType = filtersParms.calenderType;
      currentCountryType = filtersParms.countryType;
    } else {
      changePageUrl('');
    }
  }

  addBottomPadding() {
    const sideBarEle = jQuery('#sidebar .sidebar__inner').get(0);
    const columnList = jQuery('.calendar-list-column.content');
    let lastEle = jQuery('.calendar-date-wrap.econ-event').last().get(0);
    if (this.filtersParms.calenderType === 'CBD') {
      lastEle = jQuery('.calendar-date-wrap.cbd-event').last().get(0);
    }
    if (lastEle && sideBarEle) {
      const lastEleHeight = lastEle.getBoundingClientRect().height;
      const sideBarHeight = sideBarEle.getBoundingClientRect().height;
      const paddingHeight = sideBarHeight - lastEleHeight - 50;
      if (paddingHeight > 0) {
        columnList.css('padding-bottom', `${paddingHeight}px`);
      }
    }
  }
}

jQuery(document).on('click focusin', function () {
  if (jQuery('.calendar-month-dropdown').is(':visible')) {
    jQuery('.datepicker-switch:visible').attr('aria-expanded', true);
  } else {
    jQuery('.datepicker-switch:visible').attr('aria-expanded', false);
  }
});

// Document ready function
jQuery(document).ready(async function () {
  if (jQuery('.calender-view-main').length) {
    toggleEventLoader(true);
    const queryString = window.location.search.substring(1);
    const calenderControl = new CalenderControlView();
    const htmlData = await calenderControl.getCalendarHTMLStructure();

    calenderControl.setEventsData(htmlData);

    calenderControl.renderEventsData();

    toggleEventLoader(false);

    calenderControl.renderFilterData();

    stickyFilter();

    calenderControl.setFilterByQueryParam(queryString);

    calenderControl.onClickFunctions();
    calenderControl.applyFilterParams();
    calenderControl.scrollAfterModalScroll();

    showHidePrint(currentCalType);
    calenderControl.calenderWidget.calendarWidget.datepicker('update', parseURLDateToDate(currentDate));
    jQuery('.calendar-date-wrap .events-data .media-body, .cbd-data-wrap .media-body.text').each(function (i, item) {
      jQuery(item).attr('tabindex', '0');
      jQuery(item).attr('aria-label', 'downloads ics file');
    });
    windowResize();
    stickyFilterSection();
    calenderControl.addBottomPadding();
    jQuery(window).on('resize', windowResize);
    jQuery(window).on('scroll', windowScroll);
    initialLoad = false;
  }
  jQuery('.calender-view-main').attr('role', 'main');
  jQuery('.table-condensed thead th[role=button]').removeAttr('id');
  jQuery('.datepicker .datepicker-title').attr({ 'aria-hidden': true, role: 'none' });
  jQuery('.datepicker tfoot tr th').attr({ 'aria-hidden': true, role: 'none' });
  jQuery('.datepicker-switch').attr('aria-expanded', false);
  jQuery('.datepicker-switch').parent().removeAttr('aria-hidden');
  jQuery('.datepicker-switch').parent().removeAttr('role');
  if (!$('.datepicker-days table tbody tr:last-child td').is(':visible')) {
    $('.datepicker-days table tbody tr:last-child').attr({ 'aria-hidden': true, role: 'none' });
  }
  selectCurrentActiveDay();
  calenderActiveRows();
  calendarMonthExpandState();
});
