import {
  cloneChildTemplate,
  minTabletWidth,
  changePageUrl,
  parseQueryString,
  getQueryStringValue,
  BEDateFormat,
  getQueryString,
  isPublicationListingPage,
  selectCurrentActiveDay,
  calenderActiveRows,
} from './utils';

import {
  loaderBegins,
  PublicationView,
  stickyFilterSection,
  expandCollapseReset,
} from './publication_utils';

let economistFilteredList = [];

let publicationView = '';

/* Author Search modal toggle */
function onClickOutsideCloseSearchModal() {
  let hide = true;
  jQuery('body').on('click', function (e) {
    let notOnAuthorSearchModal = true;
    const targetEle = jQuery(e.target);
    // prevent the close click on the search modal.
    if (targetEle.parents('.author-search-modal').length || targetEle.attr('class') === 'remove-author') {
      notOnAuthorSearchModal = false;
    }
    if (hide && notOnAuthorSearchModal) {
      jQuery('.author-search-modal').removeClass('d-block');
      jQuery('.author-search input').css('outline', '0');
    }
    hide = true;
  });
  jQuery('.author-search input').on('focus', function () {
    if (jQuery('.author-search-modal').hasClass('d-none')) {
      jQuery('.author-list-item').attr('tabindex', '0');
      $(document).keydown(function (e) {
        if (e.which === 38) {
          jQuery('.author-list-item:focus').prev().focus();
        }
        if (e.which === 40) {
          jQuery('.author-list-item:focus').next().focus();
        }
      });
    }
    hide = false;
  });
  jQuery('.author-search input').on('change', function () {
    if (jQuery('.author-search-modal').hasClass('d-none')) {
      jQuery('.author-search-modal').addClass('d-block');
    }
    hide = false;
  });
}

// On resize window close the publication mobile modal
function windowResizeFunction() {
  jQuery(window).resize(function () {
    if (jQuery(window).width() >= minTabletWidth) {
      if (jQuery('.publication-links-mobile-modal').hasClass('show')) {
        jQuery('.publication-links-mobile-modal').modal('hide');
      }
    }
  });
}

export default function getPublicationFilters() {
  let filters = {};

  // Get sector filter
  const sectorFilter = {};

  jQuery('.publication-filters-desktop input[name^="industry"][type="checkbox"]').each(function () {
    if (typeof sectorFilter[jQuery(this).attr('name')] === 'undefined') {
      sectorFilter[jQuery(this).attr('name')] = [];
    }

    if (jQuery(this).is(':checked') === true) {
      sectorFilter[jQuery(this).attr('name')].push(jQuery(this).val());
    }
  });

  filters = _.extend(filters, sectorFilter);

  // Get location filter
  const location = [];

  jQuery('.publication-filters-desktop input[name^="location"][type="checkbox"]:checked').each(function () {
    location.push(jQuery(this).val());
  });

  filters.location = location.join(',');

  filters.from_date = jQuery('.from-date').val();
  filters.to_date = jQuery('.to-date').val();

  filters.economist_id = economistFilteredList.join(',');
  filters.keyword = getQueryStringValue('keyword');

  return filters;
}

export function changeURLbyFilter(filters) {
  const queryString = getQueryString(filters);

  changePageUrl(queryString);
}

export function loadFilteredPublications() {
  // Remove all publications from the list
  publicationView.resetPageNumber();
  publicationView.resetPublicationsList();

  const filters = getPublicationFilters();

  _.forEach(filters, function (value, key) {
    publicationView.setFilter(key, value);
  });

  const queryString = getQueryString(filters);
  changePageUrl(queryString);

  // Render publication
  publicationView.renderPublicationsListing(true);
}

// Common function for on from date change
function onFromDateChange(onChangeEle) {
  onChangeEle.on('changeDate', function () {
    // Set on mobile as well as desktop
    jQuery('.from-date').val(jQuery(this).val());

    jQuery('.date-range input.to-date').each(function () {
      jQuery(this).datepicker('setStartDate', onChangeEle.val());
    });

    loadFilteredPublications();
    selectCurrentActiveDay();
    calenderActiveRows();
  });
}

// Common function for on to date change
function onToDateChange(onChangeEle) {
  onChangeEle.on('changeDate', function () {
    // Set on mobile as well as desktop
    jQuery('.to-date').val(jQuery(this).val());

    jQuery('.date-range input.from-date').each(function () {
      jQuery(this).datepicker('setEndDate', onChangeEle.val());
    });

    loadFilteredPublications();
    selectCurrentActiveDay();
    calenderActiveRows();
  });
}

function setFilters() {
  const fromDate = jQuery('.date-range input.from-date');
  const toDate = jQuery('.date-range input.to-date');

  const queryString = window.location.search.substring(1);
  const params = parseQueryString(queryString);

  let fromDateValue = moment().subtract(3, 'months').format(BEDateFormat);
  let toDateValue = moment().format(BEDateFormat);

  /* Show All Checkbox */

  // Set filters on publication filters
  _.forEach(params, function (value, key) {
    // If there is variable in the URL, then uncheck boxes

    jQuery(`.publication-filter-accordion-js input[name^="${key}"], .publication-filter-accordion-mobile input[name^="${key}"]`).prop('checked', false);

    // If the input is checkbox then make it as checked
    if (jQuery(`input[name^="${key}"]`).length > 0 && jQuery(`input[name^="${key}"]`).attr('type') === 'checkbox') {
      const values = value.split(',');

      _.forEach(values, function (val) {
        jQuery(`input[name^="${key}"][value="${val}"]`).prop('checked', true);
      });
    }

    // If the input is checkbox then make it as checked
    if (jQuery(`input[name^="${key}"]`).length > 0 && jQuery(`input[name^="${key}"]`).attr('type') === 'checkbox') {
      const values = value.split(',');

      _.forEach(values, function (val) {
        jQuery(`input[name^="${key}"][value="${val}"]`).prop('checked', true);
      });
    }

    if (key === 'from_date') {
      fromDate.each(function () {
        fromDateValue = value;
      });
    }

    if (key === 'to_date') {
      toDate.each(function () {
        toDateValue = value;
      });
    }
  });

  fromDate.each(function () {
    /* From date */
    jQuery(this).datepicker({
      todayHighlight: true,
      autoclose: false,
      container: (jQuery(this).hasClass('from-date-mobile') ? '.from-date-container-mobile' : '.from-date-container'),
      format: 'mm/dd/yyyy',
    }).datepicker('setDate', fromDateValue).datepicker('setEndDate', moment().format(BEDateFormat));
    jQuery(this).on('keydown', function (e) {
      if ((e.keyCode === 9) || (e.which === 9)) {
        if (e.shiftKey) {
          jQuery(this).datepicker('hide');
          jQuery(this).attr('aria-expanded', false);
        }
      }
    });

    /* From date on change */
    onFromDateChange(jQuery(this));
  });

  toDate.each(function () {
    /* To date */
    jQuery(this).datepicker({
      todayHighlight: true,
      autoclose: false,
      container: (jQuery(this).hasClass('to-date-mobile') ? '.to-date-container-mobile' : '.to-date-container'),
      format: 'mm/dd/yyyy',
    }).datepicker('setDate', toDateValue).datepicker('setStartDate', moment().subtract(3, 'months').format(BEDateFormat));

    jQuery(this).on('keydown', function (e) {
      if ((e.keyCode === 9) || (e.which === 9)) {
        if (e.shiftKey) {
          jQuery(this).datepicker('hide');
          jQuery(this).attr('aria-expanded', false);
        }
      }
    });
    /* To date on change */
    onToDateChange(jQuery(this));
  });
}

function checkAll(name, checked) {
  // Check on mobile and desktop
  jQuery(`input[name="${name}"]`).prop('checked', checked);
}

function checkUncheckShowAll(name) {
  // If even one of the checkbox is not checked in that section,
  // then uncheck all
  const uncheckedCount = jQuery(`input[name="${name}"][value!="all"]:not(:checked)`).length;

  if (uncheckedCount > 0) {
    jQuery(`input[name="${name}"][value="all"]`).prop('checked', false);
  } else {
    jQuery(`input[name="${name}"][value="all"]`).prop('checked', true);
  }
}

function getAuthorModalTemplate(economistIdStr) {
  const selectedAuthorTemplate = cloneChildTemplate('author-selected-item-template').first();
  const removeAuthorTemplate = cloneChildTemplate('remove-author-template').first();
  const economistId = parseInt(economistIdStr, 10);

  // Remove class 'selectedAuthorTemplate', so that it doesn't duplicate the template HTML
  selectedAuthorTemplate.removeClass('author-selected-item-template');
  removeAuthorTemplate.removeClass('remove-author-template');

  const economistText = jQuery(`.author-list-item[data-economist-id="${economistId}"]`).first().text();

  // Set the economist name
  selectedAuthorTemplate.text(economistText);

  // Add data attribute to the li tag
  selectedAuthorTemplate.attr('data-economist-id', economistId);
  removeAuthorTemplate.attr('data-economist-id', economistId);

  // Append to the filter list
  economistFilteredList.push(economistId);

  // Add remove button html to the li tag
  selectedAuthorTemplate.append(removeAuthorTemplate);

  return selectedAuthorTemplate;
}

function addAppliedEconomistFilters() {
  const economistIdFromQuery = getQueryStringValue('economist_id');
  const parentAutorList = jQuery('.author-selected-list-on-filter ul');
  parentAutorList.empty();
  if (_.isUndefined(economistIdFromQuery) === false && economistIdFromQuery !== '') {
    const economistListFromURL = economistIdFromQuery.split(',');

    economistListFromURL.map(function (x) {
      const economistId = parseInt(x, 10);
      const selectedAuthorTemplate = cloneChildTemplate('author-selected-item-template').first();
      // Remove class 'selectedAuthorTemplate', so that it doesn't duplicate the template HTML
      selectedAuthorTemplate.removeClass('author-selected-item-template');
      const economistText = jQuery(`.author-list-item[data-economist-id="${economistId}"]`).first().text();
      // Set the economist name
      selectedAuthorTemplate.text(economistText);
      // Add data attribute to the li tag
      selectedAuthorTemplate.attr('data-economist-id', economistId);
      // Append li to the UL html
      parentAutorList.append(selectedAuthorTemplate.clone());
      return economistId;
    });
  }
}

/* On Submit close the modal and scroll to top */
function closeModalScrollTop() {
  jQuery('.author-search-modal').removeClass('d-block').addClass('d-none');
  jQuery('html, body').animate({ scrollTop: '170px' }, 500);
}

function authorModalFeature() {
  /* Author Search modal toggle */
  onClickOutsideCloseSearchModal();

  const economistIdFromQuery = getQueryStringValue('economist_id');
  if (_.isUndefined(economistIdFromQuery) === false && economistIdFromQuery !== '') {
    const economistListFromURL = economistIdFromQuery.split(',');

    economistFilteredList = economistListFromURL.map(function (x) {
      const economistId = parseInt(x, 10);
      const selectedAuthorTemplate = getAuthorModalTemplate(economistId);

      // Append li to the UL html
      jQuery('.author-selected-list ul').append(selectedAuthorTemplate);
      return economistId;
    });
  }

  /* Mobie author search close */
  jQuery('.author-search-mobile-close').on('click', function () {
    if (jQuery('.author-search-modal-mobile').hasClass('d-block')) {
      jQuery('.author-search-modal-mobile').removeClass('d-block');
    }
  });

  // Clicking on author in the suggestion list, the selected author will appear
  // in selected author list
  jQuery('.author-suggest-list').on('click', '.author-list-item', function () {
    // Check if the economist is already selected
    if (typeof jQuery(this).attr('disabled') !== 'undefined' && jQuery(this).attr('disabled') !== '') {
      return;
    }

    // Only 3 authors can be selected
    if (jQuery('.author-selected-list ul li').length === 6) {
      return;
    }

    const economistId = jQuery(this).data('economist-id');
    const selectedAuthorTemplate = getAuthorModalTemplate(economistId);

    // Append li to the UL html
    jQuery('.author-selected-list ul').append(selectedAuthorTemplate);

    // Enable submit buttoon only if one of the author is selected
    if (jQuery('.author-selected-list ul li').length > 0) {
      jQuery('.submit-author').removeClass('disable-submit').removeAttr('disabled');
    } else {
      jQuery('.submit-author').addClass('disable-submit').attr('disabled', true);
    }

    // Disable the author once clicked because we don't want user to click on it twice
    jQuery(`.author-list-item[data-economist-id="${economistId}"]`).attr('disabled', true);
    jQuery(document).on('keyup', function (event) {
      if (event.key === 'Escape') {
        jQuery('body').trigger('click');
      }
    });
  });

  // Clicking on the remove icon in the author suggestion list, should remove the author
  jQuery('.author-selected-list').on('click', '.remove-author', function () {
    // Get the economist ID and remove disabled attribute from the suggestion list
    // so that it can be clickable again once we remove the author from the selected list
    const economistId = parseInt(jQuery(this).attr('data-economist-id'), 10);
    jQuery(`.author-suggest-list li[data-economist-id="${economistId}"]`).removeAttr('disabled');

    // Remove the author element from the selected list
    jQuery(`.author-selected-list li[data-economist-id="${economistId}"]`).remove();

    // Enable submit buttoon only if one of the author is selected
    if (jQuery('.author-selected-list ul li').length > 0) {
      jQuery('.submit-author').removeClass('disable-submit').removeAttr('disabled');
    } else {
      jQuery('.submit-author').addClass('disable-submit').attr('disabled', true);
    }

    // Remove from the filtered list
    economistFilteredList.splice(economistFilteredList.indexOf(economistId), 1);

    const economistIDFromURL = getQueryStringValue('economist_id');

    if (economistFilteredList.length === 0 && economistIDFromURL !== '') {
      loadFilteredPublications();
      addAppliedEconomistFilters();
    }
  });

  jQuery('.author-suggest-list li').hide();

  jQuery('.author-search input,.author-search-input input, .author-search-input-mobile input').keyup(function () {
    // hide all authors by default
    jQuery('.author-suggest-list li').hide();
    jQuery('.author-search-modal').addClass('d-block');
    jQuery('.author-list-item').on('focus', function () {
      jQuery('.submit-section button').attr('tabindex', '0');
      jQuery('.submit-section button').focusout(function () {
        jQuery('.author-search-modal').removeClass('d-block');
      });
      jQuery('#resetFilters').focusin(function () {
        jQuery('.author-search-modal').removeClass('d-block');
      });
    });
    // Search authors by keyword
    const authors = jQuery(`.author-suggest-list li:containsi("${this.value}")`);

    // Show authors which keyword matches
    authors.show();

    const keyword = this.value;
    const reg = new RegExp(keyword, 'gi');

    authors.each(function () {
      const authorText = jQuery(this).text();

      const highlightedAuthorText = authorText.replace(reg, function (str) {
        if (str === '') {
          return '';
        }
        return `<span class="search-highlight-text">${str}</span>`;
      });
      jQuery(this).html(highlightedAuthorText);
    });
  });

  jQuery('.author-search-modal').on('click', '.submit-author', function () {
    loadFilteredPublications();
    closeModalScrollTop();
    jQuery('.publication-links-mobile-modal').modal('hide');
    addAppliedEconomistFilters();
  });
}

export function windowScrollPublication() {
  stickyFilterSection();
}

// Attribute updating for publication MOBILE filter accordions
const filterAccordionAttrChange = () => {
  const filterAccordionTitle = jQuery('.clone-publication-filter-js .card-header a');
  const filterAccordionBody = jQuery('.clone-publication-filter-js .card .collapse');
  const filterAuthorCardTitle = jQuery('.publication-filter-accordion-mobile .author-card a');
  const filterAuthorCardBody = jQuery('.publication-filter-accordion-mobile .author-card .collapse');

  filterAuthorCardTitle.attr('data-target', `${filterAuthorCardTitle.attr('data-target')}Mobile`);
  filterAuthorCardBody.attr('id', `${filterAuthorCardBody.attr('id')}Mobile`);

  filterAccordionTitle.each(function () {
    jQuery(this).attr('data-target', `${jQuery(this).attr('data-target')}-mob`);
  });

  filterAccordionBody.each(function () {
    jQuery(this).attr('id', `${jQuery(this).attr('id')}-mob`);
  });
};

// Document Ready
if (isPublicationListingPage()) {
  jQuery(document).ready(function () {
    // Initialise PublicationView Class
    publicationView = new PublicationView();

    const publicationFilterHTML = jQuery('.publication-filter-js').html();
    jQuery('.clone-publication-filter-js').html(publicationFilterHTML);

    const containsi = function (elem, i, match) {
      return (elem.textContent || elem.innerText || '').toLowerCase().indexOf((match[3] || '').toLowerCase()) >= 0;
    };

    jQuery.extend(jQuery.expr[':'], {
      containsi,
    });

    // Set filters if present in the URL
    setFilters();
    authorModalFeature();
    addAppliedEconomistFilters();

    // Clicking on "Show all" should check all the checkboxes in that section
    jQuery("input[value='all']").on('click', function () {
      if (jQuery(this).is(':checked')) {
        jQuery(this).parents('ul').find("input[type='checkbox']").prop('checked', true);
        checkAll(jQuery(this).attr('name'), true);
      } else {
        jQuery(this).parents('ul').find("input[type='checkbox']").prop('checked', false);
        checkAll(jQuery(this).attr('name'), false);
      }
    });

    /* Window resize close mobile modal */
    windowResizeFunction();

    // Set the section
    publicationView.setSection('publication-list');

    // Set the publications limit
    publicationView.setFilter(
      'limit_per_page',
      jQuery('.publication-result-card-block-wrap').data('limit'),
    );

    // Initialise sort feature
    publicationView.sortFeature();

    // Enable pagination
    publicationView.pagination();

    // If any filters are clicked, then load new set of publications
    jQuery('.publications-table-template').on('click', 'input[type="checkbox"]', function () {
      // Check on mobile and desktop
      jQuery(`input[name="${jQuery(this).attr('name')}"][value="${jQuery(this).val()}"]`).prop('checked', $(this).is(':checked'));

      // Check/uncheck "Show all" based on number of checked boxes
      checkUncheckShowAll(jQuery(this).attr('name'));

      loadFilteredPublications();
    });

    // Check/uncheck "Show all" based on number of checked boxes
    jQuery('input[type="checkbox"]').each(function () {
      checkUncheckShowAll(jQuery(this).attr('name'));
    });

    // Show loader
    loaderBegins();

    const filters = getPublicationFilters();
    _.forEach(filters, function (value, key) {
      publicationView.setFilter(key, value);
    });

    // Render publication
    publicationView.renderPublicationsListing();

    // Publication filter accordion attribute change for accessibility
    filterAccordionAttrChange();
    windowScrollPublication();
    expandCollapseReset();
    jQuery(window).on('scroll', windowScrollPublication);
  });
}
jQuery(document).ready(function () {
  jQuery('.author-search input').on('keyup', function (event) {
    if ((event.keyCode === 27) || (event.which === 27)) {
      jQuery('body').trigger('click');
    }
  });
  jQuery('.author-search input').on('keydown', function (event) {
    if (((event.keyCode === 9) || (event.which === 9)) && (event.shiftKey)) {
      jQuery('.author-search-modal').removeClass('d-block');
    }
  });
  jQuery('.author-search-modal').on('keydown', function (event) {
    if ((event.keyCode === 27) || (event.which === 27)) {
      jQuery('body').trigger('click');
      jQuery('.author-search input').focus();
    }
  });
  selectCurrentActiveDay();
  calenderActiveRows();

  jQuery('.from-date').click(function () {
    $(this).datepicker('show');
    $(this).attr('aria-expanded', true);
  });
  jQuery('.from-date').keypress(function (e) {
    if (e.which === 13) {
      $(this).datepicker('show');
      $(this).attr('aria-expanded', true);
    }
  });

  jQuery('.to-date').click(function () {
    $(this).datepicker('show');
    $(this).attr('aria-expanded', true);
  });
  jQuery('.to-date').keypress(function (e) {
    if (e.which === 13) {
      $(this).datepicker('show');
      $(this).attr('aria-expanded', true);
    }
  });

  jQuery('.from-date').on('focus blur', function () {
    jQuery('.to-date').attr('aria-expanded', false);
    if (jQuery(this).next('.datepicker').is(':visible')) {
      $(this).attr('aria-expanded', true);
    } else {
      $(this).attr('aria-expanded', false);
    }
  });

  $('.datepicker .datepicker-prv-nxt .button-container a').attr('aria-hidden', true);
  $('.datepicker .datepicker-days .datepicker-prv-nxt .button-container a').attr('aria-hidden', false);

  // jQuery('.date-range input.form-control').on('focusin', function () {
  //   jQuery('.datepicker-prv-nxt .button-container').attr('aria-hidden', true);
  //   jQuery('.table-condensed').attr('aria-hidden', true);
  //   jQuery('.btn.btn-link.closeCalendar').attr('aria-hidden', true);
  // });
  // jQuery('.date-range input.form-control').on('focusout', function () {
  //   jQuery('.datepicker-prv-nxt .button-container').attr('aria-hidden', false);
  //   jQuery('.table-condensed').attr('aria-hidden', false);
  //   jQuery('.btn.btn-link.closeCalendar').attr('aria-hidden', false);
  // });
  jQuery('.to-date').on('focus blur', function () {
    jQuery('.from-date').attr('aria-expanded', false);
    if (jQuery(this).parent().parent().find('.datepicker')
      .is(':visible')) {
      $(this).attr('aria-expanded', true);
    } else {
      $(this).attr('aria-expanded', false);
    }
  });
});

jQuery('body').on('show.bs.modal', function () {
  selectCurrentActiveDay();
  calenderActiveRows();
});

jQuery('.author-list-item').each(function (i, ele) {
  jQuery(ele).on('keydown', function (e) {
    if ((e.keyCode === 13) || (e.which === 13)) {
      jQuery(ele).trigger('click');
    }
  });
});

jQuery(document).on('keyup', function (event) {
  if ((event.keyCode === 27) || (event.which === 27)) {
    jQuery('body').trigger('click');
  }
});

jQuery('.filter-btn.date-sort').attr('aria-label', 'sorts date in ascending order');
jQuery('.filter-btn.date-sort').on('click', function () {
  jQuery('button.filter-btn').attr('aria-live', 'off');
  if (jQuery(this).find('em').hasClass('up')) {
    jQuery(this).attr('aria-label', 'sorts date in ascending order');
  } else if (jQuery(this).find('em').hasClass('down')) {
    jQuery(this).attr('aria-label', 'sorts date in descending order');
  }
  jQuery(this).attr('aria-live', 'polite');
});
jQuery('.filter-btn.author-sort').on('click', function () {
  jQuery('button.filter-btn').attr('aria-live', 'off');
  if (jQuery(this).find('em').hasClass('up')) {
    jQuery(this).attr('aria-label', 'sorts author in ascending order');
  } else if (jQuery(this).find('em').hasClass('down')) {
    jQuery(this).attr('aria-label', 'sorts author in descending order');
  }
  jQuery(this).attr('aria-live', 'polite');
});
jQuery('.filter-btn.subject-sort').on('click', function () {
  jQuery('button.filter-btn').attr('aria-live', 'off');
  if (jQuery(this).find('em').hasClass('up')) {
    jQuery(this).attr('aria-label', 'sorts publication in ascending order');
  } else if (jQuery(this).find('em').hasClass('down')) {
    jQuery(this).attr('aria-label', 'sorts publication in descending order');
  }
  jQuery(this).attr('aria-live', 'polite');
});
jQuery('.filter-btn.title-sort').on('click', function () {
  jQuery('button.filter-btn').attr('aria-live', 'off');
  if (jQuery(this).find('em').hasClass('up')) {
    jQuery(this).attr('aria-label', 'sorts title in ascending order');
  } else if (jQuery(this).find('em').hasClass('down')) {
    jQuery(this).attr('aria-label', 'sorts title in descending order');
  }
  jQuery(this).attr('aria-live', 'polite');
});
jQuery('.filter-btn.author-sort').attr('aria-label', 'sorts author in ascending order');
jQuery('.filter-btn.subject-sort').attr('aria-label', 'sorts publication in ascending order');
jQuery('.filter-btn.title-sort').attr('aria-label', 'sorts title in ascending order');
jQuery('.filter-btn.show-less-filter').attr('aria-label', 'show less detail');
jQuery('.filter-btn.show-less-filter').on('click', function () {
  if (jQuery(this).find('.bmo-chevron').hasClass('down')) {
    jQuery(this).attr('aria-label', 'show less detail');
  } else {
    jQuery(this).attr('aria-label', 'show more detail');
  }
});
jQuery('button[data-controls="News & Commentary"]').attr('aria-label', 'News & Commentary range filters menu').attr('aria-expanded', 'true');
jQuery('button[data-controls="Location"]').attr('aria-label', 'location range filters menu').attr('aria-expanded', 'true');
jQuery('button[data-controls="Fixed Income & Currencies"]').attr('aria-label', 'Fixed Income & Currencies range filters menu').attr('aria-expanded', 'true');
jQuery('button[data-controls="Regional Outlook"]').attr('aria-label', 'Regional Outlook range filters menu').attr('aria-expanded', 'true');
jQuery('button[data-controls="Commodities & Industry"]').attr('aria-label', 'Commodities & Industry range filters menu').attr('aria-expanded', 'true');
jQuery('button[data-controls="Fiscal Policy"]').attr('aria-label', 'Fiscal Policy range filters menu').attr('aria-expanded', 'true');
jQuery('button[data-controls="author"]').attr('aria-label', 'author name filters menu').attr('aria-expanded', 'true');
const modalHead = jQuery('#quickLinks .modal-header h3').text();
jQuery('#quickLinks').attr('aria-label', 'quick links');
jQuery('#quickLinks .modal-header h3').remove();
jQuery('#quickLinks .modal-header').find('h1').append(`<h1>${modalHead}</h1>`);
jQuery('#quickLinks .modal-header .close').on('keydown', function (e) {
  e.preventDefault();
  if ((e.keyCode === 9) || (e.which === 9)) {
    jQuery('#quickLinks .modal-body .split-ul li:first a').focus();
    if (e.shiftKey) {
      jQuery('#quickLinks .modal-footer .close').focus();
    }
  }
  if ((e.keyCode === 13) || (e.which === 13)) {
    jQuery(this).trigger('click');
  }
});
jQuery('#quickLinks .modal-footer .close').on('keydown', function (e) {
  e.preventDefault();
  if ((e.keyCode === 9) || (e.which === 9)) {
    jQuery('#quickLinks .modal-header button.close').focus();
    if (e.shiftKey) {
      jQuery('#quickLinks .modal-body .footer-desktop ul:nth-child(2) li:last a').focus();
    }
  }
  if ((e.keyCode === 13) || (e.which === 13)) {
    jQuery(this).trigger('click');
  }
});
