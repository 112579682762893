/* Publication Details Page View. */
import actionButtonFunctions from './publication_components/action_button';
import { PublicationView } from './publication_utils';

function showMoreCardInTab() {
  jQuery('#publicationDetailsContainer').on('click', '.show-card-toggle.more', function () {
    jQuery('.card-column').addClass('d-block');
    jQuery('.show-card-toggle.less').toggleClass('d-none');
    jQuery(this).addClass('d-none');
  });
  jQuery('#publicationDetailsContainer').on('click', '.show-card-toggle.less', function () {
    jQuery('.card-column').removeClass('d-block');
    jQuery('.show-card-toggle.more').toggleClass('d-none');
    jQuery(this).addClass('d-none');
  });
}

function chevronAction() {
  jQuery('#publicationDetailsContainer').on('click', '.right-column-cards .card-header', function () {
    const currEle = jQuery(this);
    currEle.siblings().toggleClass('d-none');
    if (currEle.hasClass('toggle-cards') === false) {
      currEle.find('.drop-down-icon').toggleClass('up');
      if (jQuery(currEle).find('.drop-down-icon').hasClass('up')) {
        jQuery(currEle).attr('aria-expanded', 'true');
      } else {
        jQuery(currEle).attr('aria-expanded', 'false');
      }
    }
  });
}

// Document ready function
jQuery(document).ready(function () {
  const parentComponent = jQuery('#publicationDetailsContainer');
  if (parentComponent.length) {
    showMoreCardInTab();
    chevronAction();
    actionButtonFunctions();

    const excludePublications = [parentComponent.data('publicationid')];

    jQuery('.publication-card-js').each(function () {
      excludePublications.push(jQuery(this).data('id'));
    });

    // Initialise PublicationView Class
    const publicationView = new PublicationView();

    // Set the section
    publicationView.setSection('you_might_also_be_interested_in');

    // Set filters
    publicationView.setFilter('section', 'you_might_also_be_interested_in');
    publicationView.setFilter('industry_bm_id', parentComponent.data('industry'));
    publicationView.setFilter('exclude_publications', excludePublications);

    // Render publication
    publicationView.renderPublicationsListing().then(function (data) {
      // if there are no publications, then we won't display the section
      if (data.hits.total.value === 0) {
        jQuery('.you-might-be-interested').addClass('d-none');
      }
    });
  }
  jQuery('#disclosure-modal a').attr('aria-label', 'navigation link');
  jQuery('.publication-action-button.search, .publication-action-button.font-size').on('keydown', function (event) {
    if ((event.keyCode === 13) || (event.which === 13)) {
      jQuery(this).toggleClass('active');
      jQuery('#searchBarHighlight').focusout(function () {
        jQuery('body').trigger('click');
        jQuery('.publication-action-button.search').focus();
      });
      jQuery('.custom-tooltip.font-size').focusout(function () {
        if (!(jQuery('.publication-action-button.font-size').hasClass('active'))) {
          jQuery('body').trigger('click');
          jQuery('.publication-action-button.font-size').focus();
        }
      });
    }
  });
  jQuery('.publication-action-button.search, .publication-action-button.font-size').on('keydown', function (event) {
    if ((event.keyCode === 9) || (event.which === 9)) {
      if (event.shiftKey) {
        jQuery('body').trigger('click');
      }
    }
  });

  jQuery('.font-change-button.size3').each(function (i, ele) {
    jQuery(ele).on('keydown', function (e) {
      const fontBtn = jQuery(ele).parent().parent().prev();
      if ((e.keyCode === 9) || (e.which === 9)) {
        e.preventDefault();
        fontBtn.removeClass('active').focus();
        if (e.shiftKey) {
          fontBtn.addClass('active');
          jQuery(this).prev().focus();
        }
      }
    });
  });
  jQuery('.font-change-button.size1').each(function (i, ele) {
    jQuery(ele).keydown(function (e) {
      const fontBtn = jQuery(ele).parent().parent().prev();
      if ((e.shiftKey) && ((e.keyCode === 9) || (e.which === 9))) {
        fontBtn.removeClass('active');
        jQuery(ele).focus();
      }
    });
  });
  jQuery('.btn.btn-link').each(function (i, ele) {
    jQuery(ele).on('keydown', function (event) {
      if ((event.keyCode === 13) || (event.which === 13)) {
        jQuery(this).trigger('click');
      }
    });
  });
  jQuery('.publication-details-container table h6').replaceWith(function () {
    const content = this.innerHTML;
    return `<h2 style="font-size:1rem;">${content}</h2>`;
  });
});
