const publicationUpdate = {
  config: {
    publicationUpdateHeader: jQuery('.publication-update-header'),
  },
  setup: function arrowToggle() {
  // Toggle function for mobile
    jQuery(this).next().toggleClass('d-none');
    jQuery(this).find('img').toggleClass('arrow-down');
    if (jQuery(this).find('img').hasClass('arrow-down')) {
      jQuery(this).attr('aria-expanded', 'true');
    } else {
      jQuery(this).attr('aria-expanded', 'false');
    }
  },
  init: function arrowToggler() {
    const currentElement = publicationUpdate.config.publicationUpdateHeader;
    if (currentElement.length) {
      currentElement.on('click', publicationUpdate.setup);
    }
  },
};
jQuery(publicationUpdate.init);
