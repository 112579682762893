// Bookmark info hide/show
import {
  cloneTemplate,
  getPublicationLink,
  getEconomistLink,
} from './utils';

import {
  getBookmarkPublicationAPI,
} from './apis';

export function getBookmarkData(cleanList) {
  // make API and pass this every where below
  const publicationAPI = getBookmarkPublicationAPI(JSON.stringify(cleanList));
  return publicationAPI;
}

export function getBookMarkFromStorage() {
  let bookMarkList = [];
  const cleanList = [];
  const bookmarkedItemsList = localStorage.getItem('bookmarkedItems');
  if (bookmarkedItemsList) {
    bookMarkList = JSON.parse(bookmarkedItemsList);
  }
  if (!_.isEmpty(bookMarkList)) {
    _.map(bookMarkList, (id) => {
      if (typeof id === 'string') {
        cleanList.push(id);
      }
    });
  }
  return cleanList;
}

export function setBookMarkToStorage(list) {
  if (_.isArray(list)) {
    localStorage.setItem('bookmarkedItems', JSON.stringify(list));
  }
}

export function setBookMarkCountInUI(bookMarkData) {
  jQuery('.bookmark-count').text(bookMarkData.length);
}

export function bookMarkHideShow(bookMarkData) {
  if (_.isEmpty(bookMarkData)) {
    jQuery('.bookmark-info').addClass('bookmark-info-active');
    jQuery('.media-list').addClass('media-list-hide');
    jQuery('.bookmark-count').addClass('bookmark-count-hide');
  } else {
    jQuery('.bookmark-info').removeClass('bookmark-info-active');
    jQuery('.media-list').removeClass('media-list-hide');
    jQuery('.bookmark-count').removeClass('bookmark-count-hide');
  }
}

export function addElementToList(obj) {
  const listElement = cloneTemplate('book-mark-list-template');
  const ele = jQuery(listElement);
  ele.find('.media-date').text(obj.date);
  ele.find('.media-name').text(obj.author);
  ele.find('.media-name').attr('aria-label', `Navigates to profile of ${obj.author}`);
  ele.find('.media-name').attr('href', getEconomistLink(obj.author_person_id));
  ele.find('.media-paragraph').text(obj.title);
  ele.find('.media-paragraph').attr('aria-label', `Navigates to ${obj.title} page`);
  ele.find('.remove-bookmarked-item').attr('aria-label', `Removes the ${obj.title} from bookmark section`);

  ele.find('.media-paragraph').attr(
    'href',
    getPublicationLink(obj.id, obj.product_id, obj.historical_publication),
  );

  if (obj.historical_publication === true) {
    ele.find('.media-paragraph').attr(
      'target',
      '_blank',
    );
  }

  ele.find('.remove-bookmarked-item').attr('data-publicationid', obj.id);
  return ele;
}

export function checkBookMarkIfBookmarked(publicationIDs) {
  jQuery('.publication-result-card, .author-modal-mobile').each(function loopingAllEle(indx, ele) {
    const rowElement = jQuery(ele);

    if (publicationIDs.indexOf(rowElement.attr('data-publicationid')) !== -1) {
      rowElement.find('.btn.bookmark-icon').removeClass('bookmark-inactive').addClass('bookmark-active');
      rowElement.find('.btn.bookmark-icon').attr('aria-label', 'unbookmark the publication');
    } else {
      rowElement.find('.btn.bookmark-icon').removeClass('bookmark-active').addClass('bookmark-inactive');
      rowElement.find('.btn.bookmark-icon').attr('aria-label', 'bookmark the publication');
    }
  });

  const publicationDetailsBookMarkButton = jQuery('.publication-action-button.btn.bookmark-icon');

  if (_.includes(publicationIDs, publicationDetailsBookMarkButton.attr('data-publicationid'))) {
    publicationDetailsBookMarkButton.addClass('active').attr('aria-label', 'unbookmarks the publication');
  } else {
    publicationDetailsBookMarkButton.removeClass('active').attr('aria-label', 'bookmarks the publication');
  }
}

export function renderBookMarkListInUI(bookMarkData) {
  jQuery('.media-list li').remove();
  bookMarkHideShow(bookMarkData);
  if (!_.isEmpty(bookMarkData)) {
    jQuery('.bookmark-count').removeClass('bookmark-count-hide');
    _.map(bookMarkData, (obj) => {
      const bookMarkList = jQuery('.media-list');
      bookMarkList.append(addElementToList(obj));
    });
  }
}

export function updateBookmarkList(bookmarkList) {
  setBookMarkCountInUI(bookmarkList);
  checkBookMarkIfBookmarked(bookmarkList);
  setBookMarkToStorage(bookmarkList);
}

export function triggerRemoveBookmarkEvent() {
  jQuery('.bookmark-notification .media-list').on('click', '.remove-bookmarked-item', function () {
    const bookmarkList = getBookMarkFromStorage();
    const ele = jQuery(this);

    const currentElement = ele;
    const articleId = currentElement.attr('data-publicationid');

    // remove from local storage and list
    bookmarkList.splice(bookmarkList.indexOf(articleId), 1);

    const bookMarkDataAjax = getBookmarkData(bookmarkList);
    bookMarkDataAjax.done(function (bookMarkData) {
      renderBookMarkListInUI(bookMarkData);

      // remove from local storage and list
      updateBookmarkList(bookmarkList);
    });
  });
}

export function onBookMarkToggle() {
  jQuery('body').on('click', '.btn.bookmark-icon', function () {
    const bookmarkList = getBookMarkFromStorage();
    const currentElement = jQuery(this);
    let newBookMarkedId = '';
    let isPublicationDetails = false;

    if (jQuery(this).parents('.publication-action-container').length) {
      isPublicationDetails = true;
    }

    if (isPublicationDetails) {
      newBookMarkedId = currentElement.attr('data-publicationid');
    } else {
      const parentElement = currentElement.parents('.publication-result-card');
      newBookMarkedId = parentElement.attr('data-publicationid');
    }

    // incase undefined publicationID
    if (newBookMarkedId) {
      if (bookmarkList.indexOf(newBookMarkedId) !== -1) {
        // remove from local storage and list
        bookmarkList.splice(bookmarkList.indexOf(newBookMarkedId), 1);

        // remove from local storage and list
        const bookMarkDataAjax = getBookmarkData(bookmarkList);

        bookMarkDataAjax.done(function (bookMarkData) {
          updateBookmarkList(bookmarkList);
          renderBookMarkListInUI(bookMarkData);
        });
      } else {
        // add to local storage and listd
        const tempbookmarkedItems = _.cloneDeep(bookmarkList);
        tempbookmarkedItems.push(newBookMarkedId);
        bookmarkList.push(newBookMarkedId);
        const bookMarkDataAjax = getBookmarkData(tempbookmarkedItems);

        bookMarkDataAjax.done(function (bookMarkData) {
          updateBookmarkList(bookmarkList);
          renderBookMarkListInUI(bookMarkData);
        });
      }
    }
  });
}

export function initialiseBookMarkUI() {
  const currElement = jQuery('#dropdownMenuLink');
  jQuery('#dropdownMenuLinkWrap, .bookmark-notification').on('mouseenter', function () {
    jQuery(currElement).attr('aria-expanded', 'true');

    // Here 0 is number of milli-seconds for the animation.
    jQuery(currElement).next('.bookmark-notification').show(0);
  }).on('mouseleave', function () {
    jQuery(currElement).attr('aria-expanded', 'false');

    // Here 0 is number of milli-seconds for the animation.
    jQuery(currElement).next('.bookmark-notification').hide(0);
  });

  currElement.focusin(function () {
    jQuery(currElement).attr('aria-expanded', 'true');
    jQuery(currElement).next('.bookmark-notification').show();
  });
}

export function initialiseBookmarkMobileUI() {
  jQuery('#dropdownMenuLink').on('touchend', function () {
    const currentElement = jQuery(this);
    jQuery(currentElement).attr(
      'aria-expanded',
      jQuery(currentElement).attr('aria-expanded') === 'false' ? 'true' : 'false',
    );

    // Here 0 is number of milli-seconds for the animation.
    jQuery(currentElement).next('.bookmark-notification').toggle(0);
  });
}

export function togglePublicationBookMarks() {
  const bookmarkList = getBookMarkFromStorage();

  if (!_.isEmpty(bookmarkList)) {
    const bookMarkDataAjax = getBookmarkData(bookmarkList);

    bookMarkDataAjax.done(function (bookMarkData) {
      setBookMarkCountInUI(bookMarkData);
      renderBookMarkListInUI(bookMarkData);
      checkBookMarkIfBookmarked(bookmarkList);
      bookMarkHideShow(bookMarkData);
    });
  }
}

jQuery(document).ready(function bookMarkJS() {
  if (jQuery('.bookmark-count-wrap').length) {
    const bookmarkList = getBookMarkFromStorage();
    setBookMarkCountInUI(bookmarkList);

    initialiseBookMarkUI();
    initialiseBookmarkMobileUI();
    triggerRemoveBookmarkEvent();
    bookMarkHideShow([]);

    togglePublicationBookMarks();
    onBookMarkToggle();
  }

  jQuery('#dropdownMenuLink').focusin(function () {
    jQuery(document).on('keyup', function (event) {
      const bookmarkEle = jQuery('.bookmark-count-icon').attr('aria-expanded');
      if (((event.keyCode === 27) || (event.which === 27)) && (bookmarkEle === 'true')) {
        jQuery('#dropdownMenuLink').focus().trigger('mouseleave');
      }
    });
    jQuery('.media-list').find('.media:last .media-paragraph').on('keydown', function (e) {
      if (((e.keyCode === 9) || (e.which === 9)) && (!e.shiftKey)) {
        e.preventDefault();
        jQuery('#dropdownMenuLink').trigger('mouseleave');
        jQuery('.megamenu-hamburger-icon').focus();
        e.stopPropagation();
      }
    });
  });
  jQuery('#dropdownMenuLink').on('keydown', function (event) {
    if ((event.keyCode === 9) || (event.which === 9)) {
      if (event.shiftKey) {
        jQuery('#dropdownMenuLink').trigger('mouseleave');
      }
    }
  });
  jQuery('.bookmark-info p:last').on('keydown', function (e) {
    if ((e.which === 9) || (e.keyCode === 9)) {
      e.preventDefault();
      jQuery('#dropdownMenuLink').attr('aria-expanded', 'false');
      jQuery('#dropdownMenuLink').trigger('mouseleave');
      jQuery('.megamenu-hamburger-icon').focus();
      e.stopPropagation();
    }
  });
});
