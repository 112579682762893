import {
  changePageUrl,
  isEconofactsPage,
} from './utils';

import {
  PublicationView,
  stickyFilterSection,
  expandCollapseReset,
  resetStickyPositionTop,
} from './publication_utils';

let publicationView = '';

function windowScrollEconofacts() {
  stickyFilterSection();
}

export default function getEconofactsFilters() {
  const excludeSubjects = [];

  jQuery('.econofacts-filter-option.selected-filter').parents('ul').find('.econofacts-filter-option').each(function () {
    excludeSubjects.push(jQuery(this).attr('data-id'));
  });

  return {
    region: jQuery('.econofacts-filter-option.selected-filter').attr('data-filter'),
    subject: jQuery('.econofacts-filter-option.selected-filter').attr('data-id'),
    exclude_subjects: excludeSubjects.join(','),
  };
}

if (isEconofactsPage()) {
  jQuery(document).ready(function () {
    // Initialise PublicationView Class
    publicationView = new PublicationView();

    // Set the section
    publicationView.setSection('econofacts');

    // Initialise sort feature
    publicationView.sortFeature();

    // Enable pagination
    publicationView.pagination();

    /* Filter option check */
    let searchQuery;
    jQuery('body').on('click', '.econofacts-filter-option', function () {
      window.PageNumber = 1;

      const currentElement = jQuery(this);
      jQuery('.econofacts-filter-option.selected-filter').removeClass('selected-filter');
      jQuery(currentElement).addClass('selected-filter');

      const filterName = jQuery(currentElement).data('filter');
      const filterValue = jQuery(currentElement).data('id');
      searchQuery = `?${filterName}=${filterValue}`;
      changePageUrl(searchQuery);

      // Remove all publications from the list
      publicationView.resetPageNumber();
      publicationView.resetPublicationsList();

      const filters = getEconofactsFilters();

      _.forEach(filters, function (value, key) {
        publicationView.setFilter(key, value);
      });

      // Render publication
      publicationView.renderPublicationsListing();
    });

    if (window.location.href.indexOf('econofacts') > -1) {
      // Get the CA selected value
      jQuery('.econofacts-filter-option.selected-filter').removeClass('selected-filter');
      if (window.location.search !== '') {
        const filterNameVar = window.location.search.substring(1).split('=');
        jQuery(`.sidebar__inner .card-header button[data-filter="${filterNameVar[0]}"]`).first().attr('aria-expanded', true);
        jQuery(`.sidebar__inner .collapse-body[data-filter="${filterNameVar[0]}"]`).first().addClass('show');

        jQuery(`.econofacts-filter-option[data-id='${filterNameVar[1]}'][data-filter='${filterNameVar[0]}']`).addClass('selected-filter');
      } else {
        jQuery('.sidebar__inner .card-header button').first().attr('aria-expanded', true);
        jQuery('.sidebar__inner .collapse-body').first().addClass('show');
        jQuery('.econofacts-filter-option').first().addClass('selected-filter');
      }
    }

    jQuery('.sidebar__inner .collapse').on('show.bs.collapse', function () {
      const expandedEle = jQuery('.sidebar__inner .collapse.show');
      expandedEle.siblings('.card-header').find('button').attr('aria-expanded', false).addClass('collapsed');
      expandedEle.removeClass('show');
      resetStickyPositionTop();
    });

    // Set the publications limit
    publicationView.setFilter(
      'limit_per_page',
      jQuery('.publication-result-card-block-wrap').data('limit'),
    );

    // Set the publications limit
    publicationView.setFilter(
      'section',
      'econofacts',
    );

    const filters = getEconofactsFilters();

    _.forEach(filters, function (value, key) {
      publicationView.setFilter(key, value);
    });

    // Render publication
    publicationView.renderPublicationsListing();

    /* Setting height of filter accordion to publication table */
    const filterHeight = jQuery('.publication-filters-desktop #sidebar').height();
    const econofactFilterheight = jQuery('#sidebar .sidebar__inner').height();
    jQuery('.main-publication-table .publication-result-card-block-wrap').css({
      'min-height': `${filterHeight + 216}px`,
    });
    jQuery('.econofacts-list-container').css({
      'min-height': `${econofactFilterheight + 100}px`,
    });

    windowScrollEconofacts();
    expandCollapseReset();
    jQuery(window).on('scroll', windowScrollEconofacts);

    jQuery('.card-header button').each(function (i, ele) {
      jQuery(ele).attr('tabindex', '0');
      jQuery(ele).keyup(function (e) {
        if (e.key === 'Enter') {
          jQuery(this).trigger('click');
          $('html, body').animate({ scrollTop: $(this).offset().top - 100 }, 'slow');
        }
      });
    });

    jQuery('.canada-econofacts-filters').each(function (i, ele) {
      jQuery(ele).find('li').attr('tabindex', '0');
      jQuery(ele).find('li').keyup(function (e) {
        if (e.key === 'Enter') {
          jQuery(this).trigger('click');
        }
      });
    });
  });
}
