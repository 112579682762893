import {
  cloneTemplate,
  horizontalResize,
  isTab,
  isMobile,
} from './utils';

const galleryElement = jQuery('#gallery');
const galleryFunctions = {
  galleryOptions: {
    // gallery width
    gallery_width: 930,
    // gallery height
    gallery_height: 730,
    // hidding thumbnail
    theme_hide_panel_under_width: 767,
    // show, hide the theme play button. The position in the theme is constant
    theme_enable_play_button: false,
    // show, hide the theme fullscreen button. The position in the theme is constant
    theme_enable_fullscreen_button: false,
    // show, hide the hidepanel button
    theme_enable_hidepanel_button: false,
    strippanel_background_color: '#ffffff', // #primaryWhite
    strippanel_enable_buttons: true,
    // thumb width
    thumb_width: 155,
    // thumb height
    thumb_height: 87,
    // thumb border color
    thumb_border_color: '#8c8c8c', // #medGray
    thumb_show_loader: false,
    slider_loader_type: 0,
    slider_arrows_skin: '',
    // true, false - enable swiping control
    slider_control_swipe: true,
    // true, false - enable zooming control
    slider_control_zoom: false,
    slider_textpanel_enable_bg: false,
    // textpanel title color. if null - take from css
    slider_textpanel_desc_color: '#8c8c8c', // #medGray
    // textpanel title font family. if null - take from css
    slider_textpanel_desc_font_family: null,
    // strip panel padding options
    strippanel_padding_top: 40,
    strippanel_padding_bottom: 40,
    // space from left of the panel
    strip_space_between_thumbs: 25,
    // avia control - move the strip according strip moseover position
    strip_control_avia: false,
    // touch control - move the strip by dragging it
    strip_control_touch: false,
  },
  designGallery: function designGallery() {
    const currentEle = jQuery('.ug-thumb-wrapper.ug-thumb-generated');
    currentEle.each(function (index, ele) {
      const thumbText = cloneTemplate('thumb-text-template');
      thumbText.text(`Page ${index + 1}`);
      jQuery(ele).append(thumbText);
    });
    galleryFunctions.mobileGalleryDesign();
  },
  // Function for toggle economist detail
  toggleEconomistDetail: function toggleEconomistDetail() {
    const arrowIcon = jQuery('.arrow-icon');
    const togglableBlock = jQuery('.togglable');
    jQuery('.arrow-icon, .name').on('click', function () {
      togglableBlock.toggleClass('d-none');
      arrowIcon.toggleClass('contact mb-2');
      arrowIcon.children('img').toggleClass('arrow-down mt-3');
    });
  },
  // Function for playing video
  playVideoFunction: function playVideoFunction() {
    const videoControl = {
      captions: {
        active: false,
      },
      tooltips: {
        controls: true,
      },
    };
    Plyr.setup('#video', videoControl);
  },
  // Function for setTimeOut
  setTimeoutFunction: function setTimeoutFunction(callfunc, delayTime) {
    setTimeout(() => {
      callfunc();
    }, delayTime);
  },
  // Function for styling gallery plugin for only mobiles
  mobileGalleryDesign: function mobileGalleryDesign() {
    if (isMobile()) {
      const itemWrapper = jQuery('.ug-item-wrapper');
      const sliderWrapper = jQuery('.ug-slider-wrapper');
      const sliderControl = jQuery('.ug-slider-control');
      const initialHeight = itemWrapper.height() - 30;
      galleryElement.height(initialHeight + 30);
      sliderControl.css('top', `${initialHeight}px`);
      sliderWrapper.height(initialHeight);
    }
  },
  // Function for hide/show loader
  loaderToggle: function loaderToggle() {
    const videocastLodaer = jQuery('.videocast-loader');
    galleryElement.toggleClass('visibility-hidden');
    videocastLodaer.toggleClass('d-none');
  },
  // Function for redesign Gallery
  redesignGallery: function redesignGallery() {
    if (isMobile()) {
      galleryFunctions.loaderToggle();
      // Set timeout is used for waiting to complete plugin resize function
      galleryFunctions.setTimeoutFunction(galleryFunctions.mobileGalleryDesign, 100);
      galleryFunctions.setTimeoutFunction(galleryFunctions.loaderToggle, 300);
    }
  },
};

jQuery(document).ready(function () {
  const videoMegaWrap = jQuery('.video_cast_mega_wrap');
  if (videoMegaWrap.length) {
    const youtubeWrap = jQuery('.youtube-wrap');
    const galleryModal = jQuery('#galleryModal');
    let count = 0;
    galleryFunctions.toggleEconomistDetail();
    if (!youtubeWrap.length) {
      galleryFunctions.playVideoFunction();
    }
    galleryModal.on('shown.bs.modal', function () {
      if (!count) {
        count = 1;
        if (isTab() || isMobile()) {
          const newOptions = galleryFunctions.galleryOptions;
          newOptions.strip_space_between_thumbs = 14;
          galleryElement.unitegallery(newOptions);
        } else {
          galleryElement.unitegallery(galleryFunctions.galleryOptions);
        }
        galleryFunctions.designGallery();
        galleryFunctions.loaderToggle();
        horizontalResize(galleryFunctions.redesignGallery);
      }
    });
  }
});
