import {
  isMobile,
  isTab,
} from './utils';

import { PublicationView } from './publication_utils';

let currentWidth = 0;
let currentHeight = 0;
let discriptionElement = null;

function showMoreHide() {
  // This is to handle the Showmore and showless for the economist details
  jQuery('.show-more-arrow').on('click', function showMoreLess() {
    discriptionElement.toggleClass('show-less');
    jQuery(this).toggleClass('rotate-arrow');
  });
}

function showChevron() {
  // This is to handle the Showmore and showless Chevron
  const charCount = discriptionElement.text().trim().length;
  const arrowElement = jQuery('.show-more-arrow');
  let showChevronArrow = false;
  if (isMobile() && charCount > 80) {
    showChevronArrow = true;
  } else if (isTab() && charCount > 625) {
    showChevronArrow = true;
  }
  if (showChevronArrow) {
    discriptionElement.addClass('show-less more-data');
    arrowElement.removeClass('rotate-arrow');
  } else {
    discriptionElement.removeClass('show-less more-data');
    arrowElement.addClass('rotate-arrow');
  }
}

function screenResize() {
  if (window.screen.width !== currentWidth || window.screen.height !== currentHeight) {
    showChevron();
    currentWidth = window.screen.width;
    currentHeight = window.screen.height;
  }
}

jQuery(document).ready(function () {
  // Recent releases function
  if (jQuery('.economist-details-container').length) {
    discriptionElement = jQuery('.economist-discription');
    if (discriptionElement.length) {
      showMoreHide();
      showChevron();
      currentWidth = window.screen.width;
      currentHeight = window.screen.height;
      jQuery(window).on('resize', screenResize);
    }
  }
  if (jQuery('div[data-publication="recent-releases"]').length) {
    const publicationView = new PublicationView();

    // Set the section
    publicationView.setSection('recent-releases');

    const limit = publicationView.section.find('.publication-result-card-block-wrap').data('limit');

    // Set the publications limit
    publicationView.setFilter(
      'limit_per_page',
      limit,
    );

    // Initialise sort feature
    publicationView.sortFeature();

    // Render publication
    publicationView.renderPublicationsListing().then(function (json) {
      const pubIDs = [];
      _.forEach(json.hits.hits, function (value) {
        pubIDs.push(value._id);
      });

      if (json.hits.total.value >= limit) {
        publicationView.showLoadMoreAlways = true;
      }

      publicationView.setFilter('publication_id', pubIDs);
    });
  }
});
