// Add the APIs that will be used.
import {
  getCookie,
  getEnv,
  BOOKMARK_LIST_API,
} from './utils';

import { getElasticSearchJSON } from './elastic_search';

function getTimeZone() {
  const timezone = jstz.determine();
  return timezone.name();
}
export function getEventsDataAPI(fromDate, toDate) {
  return new Promise((resolve, reject) => {
    const url = `${window.location.origin}/calendar/events/?startDate=${fromDate}&endDate=${toDate}&userTimezone=${getTimeZone()}&time=${new Date().valueOf()}`;
    jQuery.ajax({
      type: 'GET',
      url,
      contentType: 'text/html',
      datatype: 'html',
      success: (...args) => {
        resolve(...args);
      },
      error: () => {
        reject();
      },
    });
  });
}

export function getBookmarkPublicationAPI(publicationArray) {
  const csrftoken = getCookie('csrftoken');

  const formData = {};
  formData.csrfmiddlewaretoken = csrftoken;
  formData.publicationList = publicationArray;

  const publication = jQuery.post(BOOKMARK_LIST_API, formData);
  return publication;
}

export function downloadICSFile(id) {
  window.open(`${window.location.origin}/calendar/get_ics?eventId=${id}&userTimezone=${getTimeZone()}`, '_blank');
}

export function getPublicationsJsonData(url, filters) {
  const csrftoken = getCookie('csrftoken');

  const data = {};
  data.csrfmiddlewaretoken = csrftoken;

  _.map(filters, (filter, key) => {
    data[key] = filter;
  });

  return jQuery.post(url, data);
}

export function getAutoSearchResults(searchText) {
  return new Promise((resolve, reject) => {
    const elasticSearchJson = getElasticSearchJSON(searchText);
    const env = getEnv();
    const devMode = env === 'local';
    let url = '';
    if (devMode) {
      url = 'http://localhost:9200/_msearch/';
    } else {
      url = '/search/_msearch/';
    }
    jQuery.ajax({
      type: 'POST',
      url,
      data: elasticSearchJson,
      contentType: 'application/json',
      dataType: 'json',
      success: (...args) => {
        resolve(...args);
      },
      error: () => {
        reject();
      },
    });
  });
}
